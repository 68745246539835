import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS
} from './constants';

export function getOrders() {
  return {
    type: GET_ORDERS
  };
}

export function getOrdersSuccess(payload) {
  return {
    type: GET_ORDERS_SUCCESS,
    payload
  };
}

export function getOrdersError(err) {
  return {
    type: GET_ORDERS_ERROR,
    err
  };
}

export function createOrder(customer, meals, orderQty, orderTotal, pickUpDate) {
  return {
    type: CREATE_ORDER,
    customer,
    meals,
    orderQty,
    orderTotal,
    pickUpDate
  };
}

export function createOrderSuccess(payload) {
  return {
    type: CREATE_ORDER_SUCCESS,
    payload
  };
}

export function createOrderError(err) {
  return {
    type: CREATE_ORDER_ERROR,
    err
  };
}

export function deleteOrder(id) {
  return {
    type: DELETE_ORDER,
    id
  };
}

export function deleteOrderSuccess(id) {
  return {
    type: DELETE_ORDER_SUCCESS,
    id
  };
}