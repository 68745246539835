import { put, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import {
  GET_MEALS,
  CREATE_MEAL,
  UPDATE_MEAL,
  DEACTIVATE_ALL,
} from './constants';
import {
  getMealsSuccess,
  getMealsError,
  updateMealSuccess,
  updateMealError,
  deactivateAllSuccess,
  createMealSuccess,
  createMealError,
} from './actions';

export function* getMeals() {
  try {
    const payload = yield call(axios.get, '/meals/api/meal');
    yield put(getMealsSuccess(payload.data));
  } catch (err) {
    console.log(err);
    yield put(getMealsError(err));
  }
}

export function* createMeal({ meal }) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(meal);
  try {
    const payload = yield call(axios.post, '/meals/api/meal', body, config);
    yield put(createMealSuccess(payload.data));
  } catch (err) {
    console.log(err.response);
    yield put(createMealError(err));
  }
}

export function* updateMeal({ id, meal }) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(meal);

  try {
    const payload = yield call(
      axios.post,
      `${`/meals/api/meal/${id}`}`,
      body,
      config
    );
    yield put(updateMealSuccess(payload.data));
  } catch (err) {
    const errors = err.response.data.errors;
    console.log(errors);
    console.log(err);
    yield put(updateMealError(errors));
  }
}

export function* deactivateAll() {
  try {
    const payload = yield call(axios.post, '/meals/api/meal/deactivate-all');
    // yield put(deactivateAllSuccess(payload.data));
  } catch (err) {
    console.log(err);
  }
}

export default function* mealsSaga() {
  yield takeLatest(GET_MEALS, getMeals);
  yield takeLatest(CREATE_MEAL, createMeal);
  yield takeLatest(UPDATE_MEAL, updateMeal);
  yield takeLatest(DEACTIVATE_ALL, deactivateAll);
}
