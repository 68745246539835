import {
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  AUTH_ERROR,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT,
} from './constants';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  registrationLoading: true,
  user: null,
  userLoading: false,
};

function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REGISTER:
    case LOGIN_USER:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        registrationLoading: true,
        user: null,
      };
    case REGISTER_SUCCESS:
    case LOGIN_USER_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        registrationLoading: false,
      };
    case AUTH_ERROR:
    case REGISTER_ERROR:
    case LOGIN_USER_ERROR:
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        registrationLoading: false,
        user: null,
      };
    case LOAD_USER:
      return {
        ...state,
        isAuthenticated: false,
        userLoading: true,
        user: null,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        registrationLoading: false,
        user: payload,
        userLoading: false,
      };
    default:
      return state;
  }
}

export default auth;
