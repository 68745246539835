import MacronutrientIcon from '../assets/images/fruit.svg';
import OneRepMaxIcon from '../assets/images/weightlifting.svg';

export const tools = [
  {
    key: 1,
    name: 'Macronutrient Calculator',
    cardImg: MacronutrientIcon,
    cardSubtitle:
      'Get the perfect amount of calories, protein, carbs, and fat you should be eating every day.',
    active: true,
    route: '/tools/macronutrient-calculator',
    about:
      'Discover your ideal macronutrient intake for building muscle, losing fat, and increasing strength with the Alvaro Fitness Calculator. Determine how much protein, carbs, and fats you should eat every day to achieve your fitness goals.',
  },
  {
    key: 2,
    name: 'One Rep Max Calculator',
    cardImg: OneRepMaxIcon,
    cardSubtitle:
      'Calculate your one-repetition maximum (1RM) to optimize your strength training.',
    active: false,
    route: '/tools/one-rep-max-calculator',
    about:
      "Enhance your strength training regimen with the One Rep Max Calculator. Estimate your one-repetition maximum for various exercises, allowing you to customize your workout intensity and track your progress over time. Whether you're a beginner or an experienced lifter, this tool can help you reach your strength goals.",
  },
];
