import React from 'react';
import { Navigate } from 'react-router-dom';
import { Input, Button, Space } from 'antd';
import { connect } from 'react-redux';
import { loginUser } from '../ducks/Auth/actions';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

class Login extends React.Component {
  state = { visible: false, email: '', password: '' };

  onChange = (val, e) => {
    this.setState({ [val]: e.target.value });
  };

  render() {
    if (this.props.auth.isAuthenticated && this.props.auth.user) {
      return <Navigate to='/meals/orders' />;
    }
    return (
      <div className='auth-page'>
        <div className='auth-container'>
          <p className='title'>Login</p>
          <Space direction='vertical'>
            <Input
              placeholder='Email address'
              onChange={(e) => this.onChange('email', e)}
              style={{ height: '52px' }}
            />
            <Input.Password
              style={{ borderRadius: '10px' }}
              onChange={(e) => this.onChange('password', e)}
              placeholder='Password'
              iconRender={(visible) =>
                visible ? (
                  <EyeTwoTone
                    onClick={() =>
                      this.setState({ visible: !this.state.visible })
                    }
                  />
                ) : (
                  <EyeInvisibleOutlined
                    onClick={() =>
                      this.setState({ visible: !this.state.visible })
                    }
                  />
                )
              }
              maxLength={6}
            />
            <Button
              className='full-width-button'
              onClick={() =>
                this.props.loginUser(this.state.email, this.state.password)
              }
              type='primary'
              size='large'
            >
              Login
            </Button>
          </Space>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (email, password) => dispatch(loginUser(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
