import {
  SET_FORM_VALUES,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERROR,
  CLEAR_SUCCESS_MESSAGE,
  OPEN_REVIEW_MODAL,
  OPEN_LEGAL_MODAL,
  SET_FOOTER_NAVIGATION,
  SET_MOBILE_VIEW,
  HANDLE_DRAWER_MENU,
  SET_MACRONUTRIENT_ANSWERS,
} from './constants';

const initialState = {
  form: { name: '', email: '' },
  sendEmailLoading: false,
  sendEmailError: null,
  successMessage: null,
  isOpen: false,
  review: null,
  isLegalModalOpen: false,
  legalTopic: null,
  footerNavigation: [],
  isMobileView: false,
  isDrawerOpen: false,
  macronutrientAnswers: {
    unit: 'imperial',
    gender: 'male',
    weight: 180,
    age: 35,
    height: "5'10",
    bodyFatPercentage: 15,
    activityFactor: 1.55,
    bmrFormula: 'mifflin-st-jeor',
    goal: 'moderate-weight-loss',
    macroPreference: 'balanced',
    isCustomMacros: false,
    customMacros: { protein: 35, carbs: 35, fat: 30 },
  },
};

function global(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_FORM_VALUES:
      return {
        ...state,
        form: { ...state.form, ...payload },
      };
    case SEND_EMAIL:
      return {
        ...state,
        sendEmailLoading: true,
        sendEmailError: null,
        successMessage: null,
      };
    case SEND_EMAIL_SUCCESS:
      debugger;
      return {
        ...state,
        sendEmailLoading: false,
        sendEmailError: null,
        successMessage: action.successMessage,
      };
    case SEND_EMAIL_ERROR:
      return {
        ...state,
        sendEmailLoading: false,
        sendEmailError:
          'An error occurred while sending the email, please try again later.',
        successMessage: null,
      };
    case CLEAR_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: null,
      };
    case OPEN_REVIEW_MODAL:
      return {
        ...state,
        isOpen: action.isOpen,
        review: action.review,
      };
    case OPEN_LEGAL_MODAL:
      return {
        ...state,
        isLegalModalOpen: action.isLegalModalOpen,
        legalTopic: action.legalTopic,
      };
    case SET_FOOTER_NAVIGATION:
      return {
        ...state,
        footerNavigation: action.footerNavigation,
      };
    case SET_MOBILE_VIEW:
      return {
        ...state,
        isMobileView: action.isMobileView,
      };
    case HANDLE_DRAWER_MENU:
      return {
        ...state,
        isDrawerOpen: action.isDrawerOpen,
      };
    case SET_MACRONUTRIENT_ANSWERS:
      return {
        ...state,
        macronutrientAnswers: {
          ...state.macronutrientAnswers,
          ...action.macronutrientAnswers,
        },
      };
    default:
      return state;
  }
}

export default global;
