import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { starRating } from '../../helpers/utils';
import GoogleIcon from '../../assets/images/GoogleIcon.png';
import './index.css';

export const ReviewModal = ({
  isOpen,
  openReviewModal,
  colorBasedOnGender,
  review,
}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      className='landing-page review-card'
      open={isOpen}
      footer={null}
      closable={true}
      centered
      onCancel={() => dispatch(openReviewModal(false, null))}
    >
      <div
        className='grid justify-center text-center'
        key={review && review.author_name}
      >
        <img
          src={GoogleIcon}
          className='mb-10'
          style={{ height: '60px' }}
          alt='Google'
        />
        <div className='mb-20'>
          <p className='mb-10'>{starRating(review && review.rating)}</p>
          <p className='subtitle'>{review && review.text}</p>
        </div>
        <p className='title' style={{ fontSize: '18px' }}>
          {review && review.author_name}
        </p>
      </div>
    </Modal>
  );
};
