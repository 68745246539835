import { combineReducers } from "redux";
import meals from "./ducks/Meals/reducer";
import auth from "./ducks/Auth/reducer";
import orders from "./ducks/Orders/reducer";
import ordersDetail from "./ducks/OrdersDetail/reducer";
import ingredients from "./ducks/Ingredients/reducer";
import tools from "./ducks/Tools/reducer";
import global from "./ducks/Global/reducer";

export const rootReducer = combineReducers({
 meals,
 auth,
 orders,
 ordersDetail,
 ingredients,
 tools,
 global
})