import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LandingHeader } from '../components/LandingPage/LandingHeader';
import ExerciseHistory from '../assets/images/ExerciseHistory.png';
import TelemundoLogo from '../assets/images/TelemundoLogo.png';
import { Button } from 'antd';
import Fide from '../assets/images/FideFront.png';
import Corey from '../assets/images/CoreyBack.png';
import Pritesh from '../assets/images/PriteshFront.png';
import { GoogleReviews } from '../components/GoogleReviews/index';
import { ReviewModal } from '../components/GoogleReviews/reviewModal';
import { openReviewModal } from '../ducks/Global/actions';
import NutritionPlan from '../assets/images/NutritionPlan.png';
import WorkoutPlan from '../assets/images/WorkoutPlan.png';
import ProgressTracking from '../assets/images/ProgressTracking.png';
import { faqData } from '../data/faq';
import { RenderFaq } from '../components/Faq/index';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { RiDoubleQuotesR } from 'react-icons/ri';
import { starRating } from '../helpers/utils';
import { logEvent } from '../helpers/analytics';
import AlvaroExercise from '../assets/images/AlvaroExercise-min.png';
import MoneyBack from '../assets/images/MoneyBack.png';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import Alvaro1 from '../assets/images/Alvaro1.png';
import { IoIosArrowDroprightCircle } from 'react-icons/io';

export const HomeLanding = ({ sectionsRefs, handleScroll }) => {
  const [showArrow, setShowArrow] = useState(true);
  const sliderRef = useRef(null);
  const [isTabActive, setActiveTab] = useState({
    key: 1,
    img: WorkoutPlan,
    title: '🧬 Personalization',
    subtitle: 'No more one-size-fits-all plans.',
    text: 'Tailored workouts, nutrition, and supplement protocol designed specifically for your body, goals, and lifestyle. Everything is built with your needs in mind.',
  });
  const [activeStep, setStep] = useState({
    key: 1,
    title: 'Choose Your Program',
    subtitle: (
      <p className='subtitle'>
        For <span style={{ fontWeight: 'bold' }}>Elite 1-on-1 Coaching</span>,
        schedule a call to discuss your goals and receive tailored advice. For{' '}
        <span style={{ fontWeight: 'bold' }}>Signature Coaching</span>, you can
        sign up and start immediately .
      </p>
    ),
  });
  const isOpen = useSelector((state) => state.global.isOpen);
  const review = useSelector((state) => state.global.review);
  const featuredRef = useRef(null);
  const isMobileView = useSelector((state) => state.global.isMobileView);
  const navigate = useNavigate();

  useEffect(() => {
    const slider = sliderRef.current;

    const handleScroll = () => {
      // Hide the arrow when the user has scrolled
      if (slider.scrollLeft > 0) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
    };

    if (slider) {
      slider.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (slider) {
        slider.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const problems = [
    {
      key: 1,
      // icon: WorkoutPlan,
      title: 'I don’t have enough time.',
      icon: '⏰',
      description:
        'As a busy professional, do you struggle to find time for exercise in your hectic schedule?',
      solution: [
        {
          icon: '🚀',
          name: 'Efficient Workouts & Nutrition',
          description:
            'Your plan is designed to deliver maximum results in minimal time and help you stay on track with your workouts and meals, no matter your schedule.',
        },
        // {
        //   icon: '🥙',
        //   name: 'Easy-To-Follow Nutrition',
        //   description:
        //     "I know you're busy. We'll craft a plan that helps you stay on track with your meals, no matter your schedule.",
        // },
      ],
    },
    {
      key: 2,
      // icon: WorkoutPlan,
      title: 'I’ve tried before, and it didn’t work.',
      icon: '😕',
      description:
        'Are you often unsure about which exercises and diets are best for your goals, leading to frustration and lack of progress?',
      solution: [
        {
          icon: '📊',
          name: 'Data Driven Approach',
          description:
            'Every rep, set, and progress point is tracked in my app, ensuring that every move you make benefits your progress.',
        },
        // {
        //   icon: '🏆',
        //   name: 'Committed Coach',
        //   description:
        //     'With over 12 years of experience, I will give you clear, actionable guidance specific to your goals, so you’re always on the right track.',
        // },
      ],
    },
    {
      key: 3,
      // icon: WorkoutPlan,
      title: 'I don’t have the discipline.',
      icon: '😓',
      description:
        'Maintaining motivation and consistency can be challenging, especially with demanding careers and personal commitments.',
      solution: [
        {
          icon: '🤝',
          name: '1-on-1 Accountability',
          description:
            "With regular check-ins, personalized support, and continuous progress tracking, we'll ensure you are always on track.",
        },
        // {
        //   icon: '💬',
        //   name: 'Supportive Community',
        //   description:
        //     'Join a network of like-minded professionals who uplift and motivate each other, helping you stay consistent and inspired.',
        // },
      ],
    },
    {
      key: 4,
      // icon: WorkoutPlan,
      title: 'I’ll never be as fit as other people.',
      icon: '😔',
      description:
        'Maintaining motivation and consistency can be challenging, especially with demanding careers and personal commitments.',
      solution: [
        {
          icon: '🎯',
          name: 'Proven Transformations',
          description:
            "Yes, you can! I've helped hundreds change their bodies, even when they thought they couldn't or had tried things that didn’t work.",
        },
      ],
    },
    {
      key: 5,
      // icon: WorkoutPlan,
      title: 'Results take too long.',
      icon: '⏳',
      description:
        'Maintaining motivation and consistency can be challenging, especially with demanding careers and personal commitments.',
      solution: [
        {
          icon: '⚡️',
          name: 'Results Within The First Month',
          description:
            'We’ll implement strategies that allow you to make noticeable improvements quickly, to boost your motivation and keep you on track until the end.',
        },
      ],
    },
    {
      key: 6,
      // icon: WorkoutPlan,
      title: 'I’m too tired all the time.',
      icon: '😴',
      description:
        'Maintaining motivation and consistency can be challenging, especially with demanding careers and personal commitments.',
      solution: [
        {
          icon: '🌿',
          name: 'Holistic Approach',
          description:
            'When your nutrition, exercise, and lifestyle changes are all in sync, you’ll start to feel like you’ve gained your energy back. ',
        },
      ],
    },
  ];

  const howItWorks = [
    {
      key: 1,
      title: 'Choose Your Program',
      subtitle: (
        <p className='subtitle'>
          For <span style={{ fontWeight: 'bold' }}>Elite 1-on-1 Coaching</span>,
          schedule a call to discuss your goals and receive tailored advice. For{' '}
          <span style={{ fontWeight: 'bold' }}>Signature Coaching</span>, you
          can sign up and start immediately .
        </p>
      ),
    },
    {
      key: 2,
      title: 'Jump On A Call or Sign Up',
      subtitle: (
        <p className='subtitle'>
          <span style={{ fontWeight: 'bold' }}>For Elite 1-on-1 Coaching</span>,
          we'll jump on a 30-minute consultation call to discuss your lifestyle,
          struggles, and goals. If you opt for{' '}
          <span style={{ fontWeight: 'bold' }}>Signature Coaching</span>, we'll
          get the process started within 24 hours of your purchase.
        </p>
      ),
    },
    {
      key: 3,
      title: 'Get Prepared',
      subtitle: (
        <p className='subtitle'>
          After you sign up for either program, I'll begin crafting your
          personalized program based on the detailed assessment you'll complete
          on my app.
        </p>
      ),
    },
    {
      key: 4,
      title: 'Begin Your Transformation',
      subtitle: (
        <p className='subtitle'>
          <span style={{ fontWeight: 'bold' }}>Elite 1-on-1 Coaching</span>{' '}
          clients will have a 45-minute onboarding call to go through every
          detail of your personalized plan before you start. If you're on the{' '}
          <span style={{ fontWeight: 'bold' }}>Signature Coaching</span>, you'll
          dive straight into your customized plan with ongoing support.
        </p>
      ),
    },
  ];

  const whoFor = [
    {
      key: 1,
      title: (
        <p className='small-title mb-10'>
          <span className='highlighted'>You Feel Hopeless</span>
        </p>
      ),
      subtitle:
        "You've tried many things but still haven't seen transformative results",
      testimonial:
        "I thought there was nothing I could do to be slim again until a friend recommended me to Alvaro. He helped me optimize both my diet and exercise routines, and in less than three months I was down over 10% body weight, and I've continued to lose and tone ever since!",
      client: 'Dr. Simi R.',
      profession: 'Anesthesiologist',
    },
    {
      key: 2,
      title: (
        <p className='small-title mb-10'>
          <span className='highlighted'>
            You Don't Trust Trainers Or Coaches To Really Care
          </span>
        </p>
      ),
      subtitle: "You don't want invest on something that doesn't work.",
      testimonial:
        'My body shape changed after working out for a few months. My chest, arms, back and shoulders are bigger, and stronger than ever. Adding Alvaro’s nutrition guide and simple cardio tricks has contributed to better sleep at night and more focus during the day. I have worked out with other trainers over the years but Alvaro understands and focuses on my goals every time we workout.',
      client: 'Steve W.',
      profession: 'Director, Lead Concepts',
    },
    {
      key: 3,
      title: (
        <p className='small-title mb-10'>
          <span className='highlighted'>You Are Tired Of Making Excuses</span>
        </p>
      ),
      subtitle:
        'You need someone to hold you accountable to stay consistent until your goals are met.',
      testimonial:
        "What I've quickly learned is that if you do not follow the plan he methodically lays out for whatever your end goal is, outside of the training sessions, you may want to take a bit more time to work on your mental commitment. Though, if you're ready for a true transformation, I would not advise looking at anyone other than this guy.",
      client: 'Corey S.',
      profession: 'Founder, Ignite Staffing',
    },
    {
      key: 4,
      title: (
        <p className='small-title mb-10'>
          <span className='highlighted'>
            You've Had Bad Experiences With Trainers In The Past
          </span>
        </p>
      ),
      subtitle:
        "You've worked with trainers in the past but without an structured proven plan.",
      testimonial:
        'I’ve had 4 personal trainers before and no one is as focused or cared more about my results than him. He is always watching my form, coming up with new exercises to reach my fitness goals, and also pushes me with my diet. I highly recommend him.',
      client: 'Oscar N.',
      profession: 'Real State Agent',
    },
    // {
    //   key: 5,
    //   title: (
    //     <p className='small-title mb-10'>
    //       <span className='highlighted'>
    //         You Still Haven't Achieved Life Changing Results
    //       </span>
    //     </p>
    //   ),
    //   subtitle: 'Tired of not achieving the results you really want?',
    //   testimonial:
    //     'I only have great things to say about Alvaro. When I first began training  with Alvaro, I lost 22 lbs of fat in only two and a half months. If you want results, do what I did. Show up and always practice exactly what he teaches you. Diet and exercise always go hand in hand, and he has superb knowledge of both.   He is challenging, but the training with the education are definitely worth it.',
    //   client: 'Dr. Kevin B.',
    //   profession: 'General Doctor & Entrepreneur',
    // },
  ];

  const whatYouGet = [
    {
      key: 1,
      img: WorkoutPlan,
      title: '🧬 Personalization',
      subtitle: 'No more one-size-fits-all plans.',
      text: 'Tailored workouts, nutrition, and supplement protocol designed specifically for your body, goals, and lifestyle. Everything is built with your needs in mind.',
    },
    {
      key: 2,
      img: NutritionPlan,
      title: '💻 Technology',
      subtitle: 'Leverage the power of my custom app for your transformation.',
      text: 'My cutting-edge app tracks your workouts, nutrition, and progress. With data-driven insights, we optimize your plan continuously to make your program as efficient and effective as possible.',
    },
    {
      key: 3,
      img: ExerciseHistory,
      title: '🤝 Accountability',
      subtitle: 'Stay on track with 1-on-1 support.',
      text: 'With regular check-ins, progress updates, and personal feedback, I will keep you motivated and accountable, so you can always be on track and see amazing results.',
    },
    {
      key: 4,
      img: ProgressTracking,
      title: '🔄 Adaptation',
      subtitle: 'Your plan evolves with you.',
      text: 'As your body changes, your plan does to. We continuously monitor your progress and make necessary adjustments to your workouts and nutrition, to keep you moving forward and avoid plateaus.',
    },
  ];

  const pricingCardList = [
    'Onboarding Call',
    'Personalized Nutrition',
    'Tailored Workouts',
    'Optional Supplement Protocol',
    'Bi-Weekly Calls',
    'Weekly Check-ins',
    'Unlimited Program Modifications',
    '24/7 Contact Availability',
    'Quick Response Time',
    'Private Support & Accountability Group',
  ];

  const pricingCardListMonthly = [
    'Personalized Nutrition',
    'Tailored Workouts',
    'Optional Supplement Protocol',
    'Weekly Check-ins',
    '24/7 Contact Availability',
    '24h Response Time',
    'Private Support & Accountability Group',
  ];

  const handleStripeCheckoutClick = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Stripe Checkout Button',
    });

    const productUrl = 'https://buy.stripe.com/4gw5mj8bQ000deU5ko';

    window.open(productUrl, '_blank');
  };

  const twoPaths = [
    {
      title: "Don't Change Anything",
      icon: (
        <FaTimesCircle
          className='mr-10'
          style={{ color: '#D22F2F', marginTop: '5px' }}
        />
      ),
      subtitle: '',
      bulletPoints: [
        'Keep grinding with little to show for your hard work',
        'Keep telling yourself you can do it on your own',
        'Keep wasting time and money on things that have not worked',
      ],
    },
    {
      title: 'Take Action',
      icon: (
        <FaCheckCircle
          className='mr-10'
          style={{ color: '#2761f1', marginTop: '5px' }}
        />
      ),
      // subtitle: 'Not a sales call. 100% value.',
      bulletPoints: [
        "Achieve the goals you've been dreaming of—guaranteed",
        'Get 100% customization and ongoing support',
        'Start noticing your body, energy, and confidence improve within a few weeks',
      ],
    },
  ];

  const caseStudies = [
    {
      key: 1,
      img: (
        <img
          className='transformation-img'
          src={Corey}
          alt="Corey's transformation"
          loading='lazy'
        />
      ),
      title: 'Muscle Gain',
      subtitle: 'Increase lean muscle mass and strength.',
      text: (
        <p className='subtitle'>
          Struggled to find the time and stay committed due to a busy work
          schedule and work-related stress. He was eager for change but didn't
          have a clear path and accountability to get there.{' '}
          <span className='bold'>
            Gained 24 pounds of muscle mass in 32 weeks
          </span>{' '}
          and significantly improved overall strength within months.
        </p>
      ),
    },
    {
      key: 2,
      img: (
        <img
          src={Fide}
          className='transformation-img'
          alt="Fide's transformation"
          loading='lazy'
        />
      ),
      title: 'Weigh Loss',
      subtitle: 'Lose weight while toning.',
      text: (
        <p className='subtitle'>
          Unsure about the right exercise and nutrition plan needed for
          sustainable weight loss.{' '}
          <span className='bold'>Lost 43.5 pounds in 32 weeks</span>, toned, and
          increased strength. Experienced enhanced energy levels, improved sleep
          quality, and a significant boost in overall quality of life.
        </p>
      ),
    },
    {
      key: 3,
      img: (
        <img
          src={Pritesh}
          className='transformation-img'
          alt="Pritesh's transformation"
          loading='lazy'
        />
      ),
      title: 'Body Recomposition',
      subtitle: 'Switch body fat for muscle.',
      text: (
        <p className='subtitle'>
          As a Vegetarian, he struggled to get a balanced nutrition and
          supplementation plan that could allow him to switch fat for muscle. He
          tried other trainers but didn't get the results he wanted.{' '}
          <span className='bold'>Lost over 14% body fat in 16 weeks</span> while
          increasing muscle mass and strength.
        </p>
      ),
    },
  ];

  return (
    <div className='landing-page'>
      <div ref={sectionsRefs['#home-section-0']}>
        <LandingHeader
          color={'rgb(240, 242, 255)'}
          title={
            <span>
              Transform The Way You
              <br /> Look And Feel in Just
              <br />
              <span className='text-underlined'>16 weeks 🔥</span>
            </span>
            //  <span className='underlined light-blue'></span> <br />
          }
          subtitle={'If you are ready for drastic change, this is for you.'}
          image={AlvaroExercise}
          btnText={'Learn More'}
          bulletPoints={[
            '1-on-1 training, accountability, and support',
            'Build muscle while shredding body fat',
            'Increase your energy and boost your confidence',
            '100% customized exercise, nutrition, and supplementation',
          ]}
          btnAction={() => handleScroll(sectionsRefs['#home-section-1'])}
          textOverTitle={'ELITE 1-on-1 COACHING'}
        />
      </div>
      <div ref={featuredRef}>
        <div className='featured-banner'>
          <div className='featured-logo'>
            <p className='subtitle bold'>Featured on</p>
            <img
              src={TelemundoLogo}
              alt='Telemundo'
              style={{ height: '60px' }}
              loading='lazy'
            />
          </div>
        </div>
      </div>
      <div ref={sectionsRefs['#home-section-1']} className='section white'>
        <div className='content'>
          <div className='grid justify-center mb-5'>
            <p className='title mb-10' style={{ textAlign: 'center' }}>
              Break Free From{' '}
              <span className='text-gradient underlined-title'>
                Limiting Beliefs
              </span>
            </p>
            <p
              className='big-subtitle mb-50'
              style={{
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
              }}
            >
              Together, we’ll overcome your challenges and achieve an incredible
              transformation with coaching that is tailored just for you.
            </p>
          </div>
          <div className='container'>
            <div
              ref={sliderRef}
              className='slider three-column'
              style={{ position: 'relative' }}
            >
              {showArrow && isMobileView && (
                <IoIosArrowDroprightCircle
                  style={{
                    position: 'absolute',
                    right: 0,
                    bottom: '50%',
                    fontSize: '46px',
                    opacity: '0.7',
                  }}
                />
              )}
              {problems.map((problem) => (
                <div className='global-card light-background'>
                  <div className='grid justify-center'>
                    <span style={{ fontSize: '40px', textAlign: 'center' }}>
                      {problem.icon}
                    </span>
                    <div className='text'>
                      <p
                        className='title bold mb-30'
                        style={{
                          textAlign: 'center',
                          fontSize: '22px',
                          lineHeight: '32px',
                        }}
                      >
                        "{problem.title}"
                      </p>
                    </div>
                  </div>

                  {problem.solution.map((solution) => (
                    <div>
                      <div
                        className='grid-bullet-point'
                        style={{ alignItems: 'center' }}
                      >
                        <span className='mr-5'>{solution.icon}</span>
                        <p
                          className='subtitle text-gradient bold'
                          style={{ color: 'black' }}
                        >
                          {solution.name}
                        </p>
                      </div>
                      <p className='subtitle mb-20'>{solution.description}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div ref={sectionsRefs['#home-section-2']} className='section gray'>
        <div className='content'>
          <div className='grid grid justify-center'>
            <p className='title mb-10' style={{ textAlign: 'center' }}>
              The <span className='text-gradient underlined-title'>System</span>
            </p>
            <p
              className='big-subtitle mb-50'
              style={{
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
              }}
            >
              My 4 Unique Pillars For A Guaranteed Transformation.
            </p>
          </div>

          {/* <div className='flex justify-center mb-100'>
            <img
              className='framework-img'
              src={Framework}
              alt={'Framework'}
              loading='lazy'
            />
          </div> */}

          <div className='tab-container-horizontal'>
            {whatYouGet.map((item, index) => (
              <span
                key={index}
                style={{
                  backgroundColor:
                    isTabActive.title === item.title ? '#dce0ff' : '#f0f0f0',
                  justifyContent: 'center',
                }}
                className='tab-btn'
                onClick={() => setActiveTab(item)}
              >
                {item.title}
              </span>
            ))}
          </div>
          <div className='what-you-get-item'>
            <img src={isTabActive.img} alt='Program Feature' loading='lazy' />
            <div>
              <div className='text mt-20 mb-20'>
                <p className='small-title mb-5'>{isTabActive.title}</p>
                <div>
                  <p className='subtitle text-gradient bold mb-10'>
                    {isTabActive.subtitle}
                  </p>
                  <p className='subtitle'>✅ {isTabActive.text}</p>
                </div>
              </div>
              <div>
                <Button
                  className='landing-button'
                  type='primary'
                  onClick={() => handleScroll(sectionsRefs['#home-section-6'])}
                >
                  <span className='mr-10'>Get Started</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={sectionsRefs['#home-section-2']} className='section white'>
        <div className='content'>
          <div className='grid grid justify-center'>
            <p className='title mb-50' style={{ textAlign: 'center' }}>
              So, Who Is{' '}
              <span className='text-gradient underlined-title'>This For?</span>
            </p>
          </div>

          {whoFor.map((item, index) => (
            <div>
              <div key={item.key} className='what-you-get-item mb-50'>
                <div className='highlighted-block'>
                  {item.title}
                  <p className='subtitle'>{item.subtitle}</p>
                </div>
                <div
                  className='global-card'
                  style={{
                    position: 'relative',
                  }}
                >
                  {starRating(5)}
                  <p
                    className='subtitle mb-10'
                    style={{ maxWidth: '450px', fontStyle: 'italic' }}
                  >
                    "{item.testimonial}"
                  </p>

                  <p className='subtitle bold'>{item.client}</p>
                  <p
                    className='subtitle'
                    style={{
                      fontSize: '14px',
                      color: 'gray',
                      lineHeight: '16px',
                    }}
                  >
                    {item.profession}
                  </p>

                  <RiDoubleQuotesR
                    style={{
                      fontSize: '60px',
                      color: '#dce0ff',
                      position: 'absolute',
                      right: '0px',
                      top: '-30px',
                    }}
                  />
                </div>
              </div>
            </div>
          ))}

          <div className='grid justify-center'>
            <Button
              className='landing-button'
              type='primary'
              onClick={() => handleScroll(sectionsRefs['#home-section-6'])}
            >
              Get Started
            </Button>
            <p className='text-under-btn text-center mt-5'>
              "Hands down the best personal trainer I've ever had" - Simi
            </p>
          </div>
        </div>
      </div>

      <div
        ref={sectionsRefs['#home-section-3']}
        id='home-section-2'
        className='section gray'
        style={{ paddingBottom: 0, paddingTop: 0 }}
      >
        <div className='what-you-get-item'>
          <img src={Alvaro1} alt='Alvaro' />
          <div>
            <p className='eyebrow-text mb-10'>About your coach</p>
            <p className='title mb-10'>
              👋 Hi, I'm{' '}
              <span className='text-gradient underlined-title'>Alvaro</span>
            </p>
            <p
              className='big-subtitle mb-20'
              style={{
                width: '100%',
                maxWidth: '550px',
              }}
            >
              {/* As a dedicated fitness professional, I believe in leading by
              example. By working with me, you'll learn how to build the body
              you've always wanted—through a proven, straightforward,
              data-driven approach. I'm here to guide and support you every step
              of the way. */}
              A fitness professional with over 10 years of experience. As a
              former college tennis athlete, fitness has always been an integral
              part of my life. My mission as your coach is to{' '}
              <span className='bold'>unlock your full potential</span> and guide
              you to become the fittest and most high-performing version of
              yourself—both physically and mentally.
            </p>
            <Button
              className='landing-button'
              style={{ width: '100%' }}
              type='primary'
              onClick={() => navigate('/about')}
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>

      <div ref={sectionsRefs['#home-section-4']} className='section white'>
        <div className='content'>
          <div className='grid justify-center mb-50'>
            <p className='title mb-10' style={{ textAlign: 'center' }}>
              What Clients{' '}
              <span className='text-gradient underlined-title'>
                Have To Say
              </span>
            </p>
            <p
              className='big-subtitle'
              style={{
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
              }}
            >
              See More 5-Star Reviews on Google.
            </p>
          </div>
          <div className='container'>
            <ReviewModal
              isOpen={isOpen}
              openReviewModal={openReviewModal}
              review={review}
            />
            <GoogleReviews color={'#dce0ff'} />
          </div>
        </div>
      </div>
      <div
        ref={sectionsRefs['#home-section-3']}
        id='home-section-2'
        className='section gray'
      >
        <div className='content' style={{ alignContent: 'center' }}>
          <div className='what-you-get-item'>
            <div>
              <p className='eyebrow-text mb-10'>Alvaro Fitness Coaching</p>
              <p className='title mb-10'>
                How It{' '}
                <span className='text-gradient underlined-title'>Works</span>
              </p>
              <p
                className='big-subtitle mb-20'
                style={{
                  width: '100%',
                  maxWidth: '550px',
                }}
              >
                One-size-fits-all programs don’t work. When you sign up with me,
                everything is tailored specifically to you, whether you choose
                Elite 1-on-1 Coaching or Signature Coaching.
              </p>
            </div>
            <div>
              <div>
                {howItWorks.map((step, i) => (
                  <div className='tab-container-horizontal'>
                    <div>
                      <div
                        className='tab-btn flex align-center'
                        style={{
                          backgroundColor: '#f1f2ff',
                        }}
                        onClick={() => setStep(step)}
                      >
                        <span className='subtitle text-gradient space-between mr-10'>
                          {i + 1}.
                        </span>
                        <p className='subtitle bold'>{step.title}</p>
                      </div>
                      {step.title === activeStep.title && (
                        <div className='how-it-works-card'>
                          {activeStep.subtitle}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className='mobile-button-wrapper'>
                <Button
                  className='landing-button'
                  style={{ width: '100%' }}
                  type='primary'
                  onClick={() => handleScroll(sectionsRefs['#home-section-6'])}
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={sectionsRefs['#home-section-5']} className='section white'>
        <div className='content'>
          <div className='grid grid justify-center mb-5'>
            <p className='title mb-10' style={{ textAlign: 'center' }}>
              Case{' '}
              <span className='text-gradient underlined-title'>Studies</span>
            </p>
            <p
              className='big-subtitle mb-50'
              style={{
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
              }}
            >
              Muscle Gain - Weight Loss - Body Recomposition
            </p>
          </div>

          {caseStudies.map((c, index) => (
            <div key={c.key} className='what-you-get-item mb-50'>
              {c.img}
              <div style={{ maxWidth: '400px' }}>
                <div className='mb-20'>
                  <p className='small-title'>{c.title}</p>
                  <p className='subtitle text-gradient bold'>{c.subtitle}</p>
                </div>

                <div className='flex mb-20'>{c.text}</div>
              </div>
            </div>
          ))}
          <div className='grid justify-center'>
            <p
              className='small-title text-center'
              style={{
                fontStyle: 'italic',
                fontSize: '24px',
              }}
            >
              Be The Next Success Story!
            </p>
            <Button
              className='landing-button mt-20'
              type='primary'
              onClick={() => handleScroll(sectionsRefs['#home-section-6'])}
            >
              Get Started
            </Button>

            <p className='text-under-btn text-center mt-5'>
              "Alvaro is a true pro, an expert of his craft and a pleasure to
              work with." - Erich
            </p>
          </div>
        </div>
      </div>

      <div ref={sectionsRefs['#home-section-6']} className='section gray'>
        <div className='content'>
          <div className='grid grid justify-center mb-50'>
            <p className='title mb-20'>
              Choose{' '}
              <span className='text-gradient underlined-title'>Your Path</span>
            </p>
            <p className='big-subtitle text-center'>
              Two coaching options, one goal:{' '}
              <span className='bold'>Your transformation in 16 weeks.</span>
            </p>
          </div>

          <div className='two-column'>
            <div className='global-card pricing-card'>
              <div>
                <div className='text-center'>
                  <p
                    className='small-title mb-10 black'
                    style={{ color: 'black' }}
                  >
                    Elite 1-on-1 Coaching
                  </p>
                  <p className='subtitle mb-30'>
                    Transform your body and gain lifestyle habits that last
                    forever. Five active clients available per month. Spots open
                    every 4 months.
                  </p>
                </div>

                <div className='mb-30'>
                  {pricingCardList.map((listItem) => (
                    <div className='grid-bullet-point '>
                      <FaCheckCircle
                        className='mr-10'
                        style={{ color: 'black', marginTop: '5px' }}
                      />
                      <p style={{ fontSize: '16px' }}>{listItem}</p>
                    </div>
                  ))}
                </div>
                <Button
                  onClick={() => {
                    logEvent('Button', 'click', 'Free Strategy Call');
                    window.open(
                      'https://calendly.com/alvarofitness/consultation',
                      '_blank'
                    );
                  }}
                  className='landing-button black full-width-button'
                  type='primary'
                >
                  Book Your Call
                </Button>
              </div>
            </div>
            <div className='global-card pricing-card blue-border'>
              <div>
                <div className='text-center'>
                  {/* <p className='eyebrow-text'>
                    Muscle Gain | Fat Loss | Body Recomposition
                  </p> */}
                  <p className='small-title mb-10' style={{ color: '#2761f1' }}>
                    Signature Coaching
                  </p>
                  <p className='subtitle mb-30'>
                    An accessible way to transform with personalized guidance
                    and support.
                  </p>
                  <p className='small-title mb-10' style={{ color: '#2761f1' }}>
                    $297<span style={{ fontSize: '22px' }}>/month</span>
                  </p>
                  <p style={{ fontSize: '14px' }} className='bold mb-30'>
                    4-month program (16 weeks)
                    <br />
                    $220 per month thereafter
                  </p>
                </div>

                <div className='mb-30'>
                  {pricingCardListMonthly.map((listItem) => (
                    <div className='grid-bullet-point'>
                      <FaCheckCircle
                        className='mr-10'
                        style={{ color: '#2761F1', marginTop: '5px' }}
                      />
                      <p style={{ fontSize: '16px' }}>{listItem}</p>
                    </div>
                  ))}
                </div>
                <Button
                  onClick={() => handleStripeCheckoutClick()}
                  className='landing-button full-width-button'
                  type='primary'
                >
                  Get Started Now
                </Button>
              </div>
            </div>
          </div>
          <div
            className='mt-50'
            style={{
              maxWidth: '600px',
              margin: '50px auto 0 auto',
            }}
          >
            Both programs include access to the{' '}
            <p
              className='text-gradient bold'
              onClick={() => navigate('the-app')}
              style={{ cursor: 'pointer' }}
            >
              Alvaro Fitness proprietary app
            </p>
            , in which you will be able to access your workouts, nutrition,
            recipes, track your progress, and much more.
          </div>
        </div>
      </div>

      <div className='section gray' style={{ padding: '0 30px 80px 30px' }}>
        <div className='guarantee-section blue'>
          <div className='text-content'>
            <p className='small-title mb-10' style={{ color: 'white' }}>
              100% Money-Back Guarantee
            </p>
            <p className='subtitle' style={{ color: 'white' }}>
              With my Money Back Guarantee, your only risk is not taking action.
            </p>
          </div>
          <div className='guarantee-card'>
            <p>🧬 100% Personalization</p>
            <p>📉 No BS, Just Amazing Results</p>
            <p>✅ Noticeable Body Improvement</p>
          </div>
          <img src={MoneyBack} alt='Money Back' />
        </div>
      </div>

      <div ref={sectionsRefs['#home-section-2']} className='section white'>
        <div className='content'>
          <div className='grid grid justify-center mb-50'>
            <p className='title' style={{ textAlign: 'center' }}>
              You Have 2 Paths{' '}
              <span className='text-gradient underlined-title'>
                In Front Of You...
              </span>
            </p>
          </div>

          <div className='two-column'>
            {twoPaths.map((path, i) => (
              <div className='global-card' style={{ maxWidth: '450px' }}>
                <div className='grid justify-center'>
                  <div className='title text-gradient mb-5'>{i + 1}</div>
                  <p
                    className={`small-title text-center ${
                      path.subtitle ? 'mb-10' : 'mb-20'
                    }`}
                  >
                    {path.title}
                  </p>
                  {path.subtitle && (
                    <p className='subtitle mb-20'>{path.subtitle}</p>
                  )}
                </div>
                {path.bulletPoints.map((point) => (
                  <div className='grid-bullet-point'>
                    {path.icon}
                    <p className='subtitle'>{point}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className='grid justify-center'>
            <Button
              className='landing-button mt-50'
              type='primary'
              onClick={() => handleScroll(sectionsRefs['#home-section-6'])}
            >
              Get Started
            </Button>
            <p className='text-under-btn text-center mt-5'>
              "If I could give 10 stars here, I would" - Corey
            </p>
          </div>
        </div>
      </div>
      <div
        ref={sectionsRefs['#home-section-7']}
        className='content centered-container'
      >
        <div className='max-width-container mt-50'>
          <RenderFaq
            faqData={faqData.onsiteProgramFaq}
            isProductLanding={true}
          />
        </div>
      </div>
    </div>
  );
};
