export const logEvent = (category, action, label) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'button_click',
    event_category: category,
    event_action: action,
    event_label: label,
    page_location: window.location.href,
  });
};
