import {
  GET_INGREDIENTS,
  GET_INGREDIENTS_SUCCESS,
  GET_INGREDIENTS_ERROR
} from './constants';
  
  const initialState = {
    ingredients: null,
    ingredientsLoading: false,
    ingredientsError: null
  };
  
  function ingredients(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_INGREDIENTS:
        return {
          ...state,
          ingredients: null,
          ingredientsLoading: true,
        };
      case GET_INGREDIENTS_SUCCESS:
        return {
          ...state,
          ingredients: payload,
          ingredientsLoading: false
        };
      case GET_INGREDIENTS_ERROR:
        return {
          ...state,
          ingredients: null,
          ingredientsLoading: false,
          ingredientsError: payload
        };
      default:
        return state;
    }
  }
  
  export default ingredients;