import React from 'react';
import { Progress, Input, Button, Radio } from 'antd';
import { capitalize } from '../../helpers/utils';
import { faqData } from '../../data/faq';
import { RenderFaq } from '../Faq';
import { LeadForm } from '../Form';
import { MacroResults } from './results';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import './index.css';

export const MacronutrientCalculatorComponent = (props) => {
  const { t, i18n } = useTranslation();

  const metricOptions = [
    {
      label: 'Imperial',
      value: 'imperial',
    },
    {
      label: 'Metric',
      value: 'metric',
    },
  ];

  const formInfo = {
    title: t('form.title'),
    subtitle: t('form.subtitle'),
    buttonText: t('form.buttonText'),
    url: '/api/sendgrid/send-macro-email',
    templateId:
      i18n.language === 'en'
        ? 'd-b2486d4983194680877e78cff466177b'
        : 'd-e7c3ba285c7c4544be5b0625bf53a565',
    tags: ['Subscriber', 'Tools', '52 Recipes Ebook'],
    subject: t('form.subject'),
    source: 'macronutrients',
  };

  const style = {
    width: '100%',
    paddingBottom: '50px',
  };

  return (
    <div
      className='global-card dark'
      style={{ padding: '0px', border: 'none' }}
    >
      <div className='questions-container'>
        {/* <LanguageSwitcher /> */}
        <div>
          <Radio.Group
            className='mb-20'
            options={metricOptions}
            onChange={(e) => props.handleDropdownValue('unit', e.target.value)}
            optionType='button'
            buttonStyle='solid'
            defaultValue={props.macronutrientAnswers.unit}
          />
          <div className='two-col-input'>
            {props.renderDropdown(
              props.questionOptions.gender.options,
              props.questionOptions.gender.name,
              'gender'
            )}
            {props.renderInputField(props.questionOptions.age.name, 'age')}
          </div>
          <div className='two-col-input'>
            {props.renderInputField(
              props.questionOptions.weight.name,
              'weight'
            )}
            {props.macronutrientAnswers.unit === 'imperial'
              ? props.renderDropdown(
                  props.questionOptions.height.options,
                  props.questionOptions.height.name,
                  'height'
                )
              : props.renderInputField(
                  props.questionOptions.height.name,
                  'height'
                )}
          </div>
          <div className='two-col-input'>
            {props.renderDropdown(
              props.questionOptions.activityFactor.options,
              props.questionOptions.activityFactor.name,
              'activityFactor'
            )}
            <div>
              {props.renderDropdown(
                props.questionOptions.bmrFormula.options,
                props.questionOptions.bmrFormula.name,
                'bmrFormula'
              )}
              {props.macronutrientAnswers.bmrFormula === 'katch-mcardle'
                ? props.renderInputField(
                    t('questions.bodyFatPercentage.label'),
                    'bodyFatPercentage'
                  )
                : null}
            </div>
          </div>

          {props.renderDropdown(
            props.questionOptions.goal.options,
            props.questionOptions.goal.name,
            'goal'
          )}

          <Button
            loading={props.isLoading}
            onClick={() => props.handleCalculateAndShowResults()}
            size='large'
            type='primary'
          >
            {t('buttons.getMyResults')}
          </Button>
        </div>
      </div>
    </div>
  );
};
// <MacroResults
//     renderProgress={renderProgress}
//     handleShowResult={props.handleShowResult}
//     setMacroPreference={props.setMacroPreference}
//     renderCustomMacrosInput={renderCustomMacrosInput}
//     macronutrientAnswers={props.macronutrientAnswers}
//     results={props.results}
//     isMobileView={props.isMobileView}
//   />
// <div className='mb-100'>
//     <LeadForm
//       goal={props.macronutrientAnswers.goal}
//       gender={props.macronutrientAnswers.gender}
//       results={props.results}
//       macroPreference={props.macronutrientAnswers.macroPreference}
//       percentageError={props.percentageError}
//       formInfo={formInfo}
//       step={true}
//     />
//   </div>
// <RenderFaq
//     faqData={faqData.macronutrientCalculator}
//     style={style}
//     useTranslationFlag={true}
//   />
