import React, {useState} from 'react';
import { Popconfirm, Divider, Badge } from 'antd';
import { DownOutlined, UpOutlined, DeleteOutlined } from '@ant-design/icons';
import { MdCheckCircle, MdRadioButtonUnchecked } from "react-icons/md";
import moment from 'moment';

export const OrdersDetailCard = (props) => {
  const [isExpanded, handleCard] = useState(false);

  const handleIngredientCheck = (ingredient) => {
    let orderDetail = {...props.orderDetail};
    orderDetail.ingredients[ingredient.name].checked = !ingredient.checked;
    props.updateOrderDetail(props.orderDetail._id, props.orderDetail);
  }
  
  return (
    <div key={props.orderDetail._id}>
      <div onClick={() => handleCard(!isExpanded)} className='order-detail-card'>
        <div className='flex column align-center'>
          <p className='subtitle bold'>Meals</p>
          <p className='subtitle'>{props.orderDetail.totalMeals}</p>
        </div>
        <div className='flex  column align-center'>
          <p className='subtitle bold'>Date range</p>
          <p className='subtitle'>{moment(props.orderDetail.orderFromTo.dateStart).format('MM/DD/YY')} - {moment(props.orderDetail.orderFromTo.dateEnd).format('MM/DD/YY')}</p>
        </div>
        <div className='flex column align-center'>
          <p className='subtitle bold'>Date created</p>
          <p className='subtitle'>{moment(props.orderDetail.date).format('MM/DD/YY')}</p>
        </div>
        <div className='flex  column align-center'>
          <p className='subtitle bold'>Total</p>
          <p className='subtitle'>${(props.orderDetail.totalCost).toFixed(2)}</p>
        </div>
        <Popconfirm
          title="Are you sure to delete this order detail?"
          onConfirm={() => props.deleteOrderDetail(props.orderDetail._id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined style={{color: 'red'}}/>
        </Popconfirm>
        <div className='flex  column align-center'>
          {isExpanded ? <DownOutlined/> : <UpOutlined/>}
        </div>
      </div>
      {isExpanded && 
      <div style={{borderRadius: '0px 0px 10px 10px'}} className='expanded-card'>
        {Object.values(props.orderDetail.ingredients).map(ingredient =>  
          <div className='flex space-between'>
            <div>
              <p className='title' style={{margin: '0 10px 5px 0'}}>{ingredient.name}</p>
              <div className='flex'>
                <p className='subtitle'>{ingredient.grams?.toFixed(1)} g</p>
                <Badge style={{padding: '0px 5px'}} color="blue"/>
                <p className='subtitle'>{ingredient.oz?.toFixed(1)} oz</p>
                <Badge style={{padding: '0px 5px'}} color="blue" />
                <p className='subtitle'>{ingredient.lb?.toFixed(1)} lb</p>
                <Badge style={{padding: '0px 5px'}} color="blue"/>
                <p className='subtitle'>{ingredient.qty?.toFixed(1)} qty</p>
              </div>
              <Divider />
            </div>
            {ingredient.checked ?
            <MdCheckCircle onClick={() => handleIngredientCheck(ingredient)} className='icon' style={{fontSize: '24px', color: '#2761f1'}}/>  :
            <MdRadioButtonUnchecked onClick={() => handleIngredientCheck(ingredient)} className='icon' style={{fontSize: '24px', color: '#2761f1'}}/> }
          </div>
        )}
      </div>
      }
    </div>  
  )
}

export default OrdersDetailCard;