import React, { Component } from 'react';
import { Button } from 'antd';
import './index.css';

class AdminActions extends Component {
  state={adminActions: []};

  componentDidMount(){
    this.setState({adminActions: [
      // {key: 1, name: 'Deactivate all', action: () => this.props.deactivateAll()},
      {key: 2, name: 'Send email to clients'}
    ]})

  }
  render() {
    return (
      <div className='flex'>
        {this.state.adminActions.map(adminAction => {
          return(
            <Button 
              key={adminAction.key}
              onClick={() => adminAction.action()}
              type="primary" 
              size={'large'}
              style={{marginRight: '5px'}}
            >
              {adminAction.name}
            </Button>
          )
        })}
      </div>
    )
  }
}

export default AdminActions;