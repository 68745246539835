import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux'; 
import { register } from "../ducks/Auth/actions";
import { Input, Space, Button } from 'antd';
import '../index.css';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

class Register extends React.Component {
  state={visible: false, name: '', email: '', password: ''}

  onChange = (val, e) => {
    this.setState({[val]: e.target.value})
  }

  render(){
    if(this.props.auth.isAuthenticated && this.props.auth.user){
      return <Navigate to='/orders'/>
    }
    return(
      <div className='auth-page'>
        <div className='auth-container'>
            <p className='title'>Register</p>
            <Space direction="vertical">
            <Input 
                placeholder="Name" 
                onChange={e => this.onChange('name', e)}
              />
              <Input 
                placeholder="Email address" 
                onChange={e => this.onChange('email', e)}
              />
              <Input.Password
                onChange={e => this.onChange('password', e)}
                placeholder="Password"
                iconRender={visible => (visible ? <EyeTwoTone onClick={() => this.setState({ visible: !this.state.visible})}/> : <EyeInvisibleOutlined onClick={() => this.setState({ visible: !this.state.visible})}
                />)}
                maxLength={6}
              />
              <Button 
                onClick={() => this.props.register(this.state.name, this.state.email, this.state.password)}
                type="primary" 
              > 
                Register
              </Button>
              <Link to='/login'>Login</Link>
            </Space >
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    register: (name, email, password) => dispatch(register(name, email, password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);