import React from 'react';
import { Modal } from 'antd';
import { openLegalModal } from '../../ducks/Global/actions';
import { useDispatch } from 'react-redux';

export const GlobalModal = ({ isVisible, content, style }) => {
  const dispatch = useDispatch();

  return (
    <div style={style}>
      <Modal
        open={isVisible}
        onCancel={() => dispatch(openLegalModal(false))}
        footer={null}
        centered
      >
        {content}
      </Modal>
    </div>
  );
};
