import {
  GET_MEALS,
  GET_MEALS_SUCCESS,
  GET_MEALS_ERROR,
  CREATE_MEAL,
  CREATE_MEAL_SUCCESS,
  CREATE_MEAL_ERROR,
  UPDATE_MEAL,
  UPDATE_MEAL_SUCCESS,
  UPDATE_MEAL_ERROR,
  HANDLE_MEAL_QTY,
  DEACTIVATE_ALL,
  DEACTIVATE_ALL_SUCCESS,
} from './constants';

export function getMeals() {
  return {
    type: GET_MEALS,
  };
}

export function getMealsSuccess(payload) {
  return {
    type: GET_MEALS_SUCCESS,
    payload,
  };
}

export function getMealsError(err) {
  return {
    type: GET_MEALS_ERROR,
    err,
  };
}

export function createMeal(meal) {
  return {
    type: CREATE_MEAL,
    meal,
  };
}

export function createMealSuccess(payload) {
  return {
    type: CREATE_MEAL_SUCCESS,
    payload,
  };
}

export function createMealError(err) {
  return {
    type: CREATE_MEAL_ERROR,
    err,
  };
}

export function updateMeal(id, meal) {
  return {
    type: UPDATE_MEAL,
    id,
    meal,
  };
}

export function updateMealSuccess(payload) {
  return {
    type: UPDATE_MEAL_SUCCESS,
    payload,
  };
}

export function updateMealError(err) {
  return {
    type: UPDATE_MEAL_ERROR,
    err,
  };
}

export function handleMealQty(meals, mealSelected, btn) {
  let orderedMeals = [...meals];
  let meal = orderedMeals.find((meal) => meal._id === mealSelected._id);
  if (btn === 'add') {
    meal.qty += 1;
  } else if (btn === 'subtract' && meal.qty !== 0) {
    meal.qty -= 1;
  }

  let orderTotal = meals.reduce(function (prev, cur) {
    return prev + cur.price * cur.qty;
  }, 0);

  let orderQty = meals.reduce(function (prev, cur) {
    return prev + cur.qty;
  }, 0);

  return {
    type: HANDLE_MEAL_QTY,
    orderedMeals,
    orderTotal,
    orderQty,
  };
}

export function deactivateAll() {
  return {
    type: DEACTIVATE_ALL,
  };
}

export function deactivateAllSuccess(payload) {
  return {
    type: DEACTIVATE_ALL_SUCCESS,
    payload,
  };
}
