import React, { Component } from 'react';
import TablePage from '../../components/Table';
import moment from 'moment';
import './index.css';
import { DatePicker, Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { formatCustomer } from '../../helpers/utils';
const { RangePicker } = DatePicker;

export default class OrdersPage extends Component {
  state={
    orders: [], 
    filteredOrders: [], 
    dateStart: null, 
    dateEnd: null, 
    isFiltered: false, 
    ingredients: [],
    addedIngredients: []
  }

  componentDidMount(){
    this.setState({orders: this.props.orders, filteredOrders: this.props.orders})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.orders !== this.props.orders) {
      this.setState({orders: this.props.orders, filteredOrders: this.props.orders})
    }
  }

  renderMeals = (order) => {
    return order.map(meal => {
      return (
        <div className='flex'>
          <span style={{lineHeight: '16px'}}>{meal.qty}</span>
          <span style={{lineHeight: '16px'}}>-</span>
          <span style={{lineHeight: '16px'}}>{meal.name}</span>
        </div>
      )
    })
  }

  handleCreateOrderDetails = () => {
    let ingredients = [...this.state.ingredients];
    this.state.filteredOrders.map(order => {
      return order.orderedMeals.map(orderedMeal => {
        return orderedMeal.ingredients.map((ingObj) => {
          for(var i = 0; i < orderedMeal.qty; i++){
            ingredients.push(ingObj)
          }
          this.setState({ingredients: ingredients})
        })
      })
    })
  
    let addedIngredients = ingredients.reduce((acc,curr)=>{
      if(!acc[curr.name]){
        acc[curr.name] = {name:curr.name, grams:0, oz:0, lb: 0, qty: 0, checked: false};
      }
      acc[curr.name].grams +=curr.grams;
      acc[curr.name].oz +=curr.oz;
      acc[curr.name].qty +=curr.qty;
      acc[curr.name].lb +=curr.lb;
      
      return acc;
 
     },{})

    // ingredients, totalMeals, totalCost, orderFromTo
    let filteredOrders = this.state.filteredOrders;
    const totalMeals = filteredOrders.reduce((accumulator, object) => {
      return accumulator + object.orderQty;
    }, 0);
    const totalCost = filteredOrders.reduce((accumulator, object) => {
      return accumulator + object.orderTotal;
    }, 0);
    const orderFromTo = {dateStart: this.state.dateStart || '-', dateEnd: this.state.dateEnd || "-"};
    this.props.createOrderDetail(addedIngredients, totalMeals, totalCost, orderFromTo);
  }

  renderDateRangeFilter = () => {
    return(
      <div className='orders-action-menu'>
        <RangePicker 
          className='menu-item'
          allowClear={true}
          picker="week" 
          size={'large'}
          format={'MMM DD, YYYY'}
          onChange={(date) => this.setState({ dateStart: date[0], dateEnd: date[1] })}
        />
        <Button size='large' type="primary" className='menu-item' onClick={() => this.filterTableData()}>Filter</Button>
        <Button size='large' className='menu-item' type='primary' onClick={() => this.handleCreateOrderDetails()}>Create Order Details</Button>
        <Button type='secondary' className='menu-item' onClick={() => this.setState({filteredOrders: this.state.orders, dateStart: null, dateEnd: null})}>Reset</Button>
      </div>
    )
  }

  filterTableData = () => {
    let filteredOrders = [...this.state.orders];
    let selection = filteredOrders.filter(order => {
      return moment(order.date).format() >= moment(this.state.dateStart).format() &&
      moment(order.date).format() <= moment(this.state.dateEnd).format()
    })
    this.setState({filteredOrders: selection, isFiltered: true})
  }

  renderDelete = (order) => {
    return(
      <Popconfirm
        title="Are you sure to delete this order?"
        onConfirm={() => this.props.deleteOrder(order._id)}
        okText="Yes"
        cancelText="No"
      >
        <DeleteOutlined />
      </Popconfirm>
    )
  }

  columnsOrders = () => {
    return [
      {title: 'Order date', dataIndex: "date", width: '100px', render: (e, record) => moment(record.date).format("MMM D, YY")},
      {title: 'Customer', dataIndex: "customer", key: 'name', width: '80px', render: (e, record) => formatCustomer(record.customer)},
      // {title: 'Email', dataIndex: "customer['email']", key: 'email', width: '100px', render: (e, record) => (record.customer.email)},
      {title: 'Meals', dataIndex: "orderedMeals", key: 'orderedMeals', width: '200px', render: (e, record) => this.renderMeals(e, record)},
      {title: 'Quantity', dataIndex: 'qty', key: 'qty', width: '80px', render: (e, record) => record.orderQty},
      {title: 'Total', dataIndex: "orderTotal", key: 'orderTotal', width: '100px', render: (e, record) => `$${record.orderTotal.toFixed(2)}`},
      {title: 'Pick Up', dataIndex: "pickUpDate", key: 'pickUpDate', width: '100px', render: (e, record) => record.pickUpDate ? moment(record.pickUpDate).format('ddd Do') : '-'},
      {title: '', dataIndex: "", width: '20px', render: (e, record) => this.renderDelete(e, record)},
    ];
  }

  tableHeader = () => {
    return(
      <p className='table-count'>Showing {this.state.filteredOrders && this.state.filteredOrders.length} orders</p>
    )
  }

  render() {
    return (
      <div>
        {this.renderDateRangeFilter()}
        <TablePage 
          {...this.props}
          tableDataLoading={!this.state.filteredOrders}
          columns={this.columnsOrders()}
          tableData={this.state.filteredOrders}
          isDisabledRowHover
          tableName="orders"
          // tableUrl={`${process.env.APP_API_URL}accounts/${this.props.group.id}/campaigns`}
          scroll={{x: true}}
          tableHeader={this.tableHeader()}
        /> 
      </div>
    )
  }
}