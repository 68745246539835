import React, { Component } from 'react';
import { AutoComplete, Input, Button } from 'antd';
import './index.css';
import { Ingredient } from './ingredient';
import { convertMeasurement } from '../../helpers/utils';

const { Option } = AutoComplete;

export default class ManageMealsPage extends Component {
  state={
    meal: {},
    ingredientsList: [],
    filteredIngredients: [],
    ingredients: [],
    mealIngredients: [],
    mealIngredient: {},
    ingredientErr: ''
  };

  componentDidMount(){
    this.setState({
      ingredientsList: this.props.ingredientsList, 
      filteredIngredients: this.props.ingredientsList
    })
  }

  handleSearch = (value) => {
    const { ingredientsList } = this.state;
    let filtered;
    value === "" 
    ? filtered = ingredientsList 
    : filtered = ingredientsList.filter(ingredient => ingredient.name.toLowerCase().includes(value))
    this.setState({filteredIngredients: filtered}) 
  }

  handleForm = (value, key) => {
    let meal = {...this.state.meal};
    if(key === 'name' || key === 'description' ||  key === 'price'){
      meal[key] = key === 'price' ? Number(value) : value;
    } else if(key === 'calories' || key === 'protein' || key === 'carbs' || key === 'fat'){
      meal.nutrition = {...meal.nutrition, [key]: Number(value)}
    }
    this.setState({meal: meal})
  }

  handleIngredientChange = (value, key, e) => {
    let mealIngredient = {...this.state.mealIngredient};
    let name = {[key]: value};
    let measurements = convertMeasurement(key, Number(value));
    mealIngredient = Object.assign(mealIngredient, name, measurements);
    this.setState({mealIngredient: mealIngredient});
  }

  addIngredient = () => {
    let mealIngredients = [...this.state.mealIngredients];
    let mealIngredient = {...this.state.mealIngredient};
    if(Object.keys(mealIngredient).length !== 5){
      this.setState({ingredientErr: 'All ingredient fields are required'})
    } else {
      mealIngredients.push(this.state.mealIngredient);
      this.setState({mealIngredients: mealIngredients, mealIngredient: {}, ingredientErr: ''})
    }
  }

  removeIngredient = (name) => {
    let mealIngredients = [...this.state.mealIngredients];
    let updatedIngredients = mealIngredients.filter(ingredient => ingredient.name !== name)
    this.setState({mealIngredients: updatedIngredients})
  }

  handleMealCreation = () => {
    let meal = {...this.state.meal};
    meal.ingredients = this.state.mealIngredients;
    this.props.createMeal(meal);
  }

  render() {
    return (
      <div className='manage-meals'>
        <div className='create-meal-container'>
          <h2 className='title mb-20'>Create New Meal</h2>
           <div className='mb-20'>
            <p className='label'>Name</p>
            <Input onChange={(e) => this.handleForm(e.target.value, 'name')}/>
          </div>
          <div className='mb-20'>
            <p className='label'>Description</p>
            <Input onChange={(e) => this.handleForm(e.target.value, 'description')}/>
          </div>
          <div className='mb-20'>
            <p className='label'>Price</p>
            <Input type='number' onChange={(e) => this.handleForm(e.target.value, 'price')}/>
          </div>
          <div className='macro-inputs'>
            <div>
              <p className='label'>Calories</p>
              <Input type='number' onChange={(e) => this.handleForm(e.target.value, 'calories')}/>
            </div>
            <div>
              <p className='label'>Protein</p>
              <Input type='number' onChange={(e) => this.handleForm(e.target.value, 'protein')}/>
            </div>
            <div>
              <p className='label'>Carbs</p>
              <Input type='number' onChange={(e) => this.handleForm(e.target.value, 'carbs')}/>
            </div>
            <div>
              <p className='label'>Fat</p>
              <Input type='number' onChange={(e) => this.handleForm(e.target.value, 'fat')}/>
            </div>
          </div>
          <div style={{marginBottom: '30px'}}>
            {this.state.mealIngredients && this.state.mealIngredients.length > 0 && <p className='label'>Ingredients</p>}
            {this.state.mealIngredients && this.state.mealIngredients.length > 0 && this.state.mealIngredients.map(ingredient => 
            <Ingredient 
              key={ingredient.key}
              ingredient={ingredient} 
              removeIngredient={this.removeIngredient}
            />)}
          </div>

          <div className='mb-20'>
            <div className='ingredient-inputs'>
              <div>
                <p className='label'>Ingredient</p>
                <AutoComplete 
                  onSearch={(value) => this.handleSearch(value)} 
                  style={{ width: 200 }}
                  onSelect={(value, e) => this.handleIngredientChange(value,'name', e)}
                >
                  {this.state.filteredIngredients && this.state.filteredIngredients.length > 0 && this.state.filteredIngredients.map((ingredient) => (
                    <Option key={ingredient._id} value={ingredient.name}>
                      {ingredient.name}
                    </Option>
                  ))}
                </AutoComplete>
              </div>
              <div>
                <p className='label'>Grams</p>
                <Input 
                  type='number' 
                  onChange={(e) => this.handleIngredientChange(e.target.value, 'grams', e)}
                  value={this.state.mealIngredient['grams']}
                />
              </div>
              <div>
                <p className='label'>Oz</p>
                <Input 
                  type='number'
                  onChange={(e) => this.handleIngredientChange(e.target.value, 'oz', e)}
                  value={this.state.mealIngredient['oz']}
                />
              </div>
              <div>
                <p className='label'>Lb</p>
                <Input 
                  type='number'
                  onChange={(e) => this.handleIngredientChange(e.target.value, 'lb', e)}
                  value={this.state.mealIngredient['lb']}
                />
              </div>
              <div>
                <p className='label'>Qty</p>
                <Input 
                  type='number'
                  onChange={(e) => this.handleIngredientChange(e.target.value, 'qty', e)}
                  value={this.state.mealIngredient['qty']}
                />
              </div>
              <div>
                <p>-</p>
                <Button 
                  onClick={() => this.addIngredient()}
                  type='primary'
                  size="large"
                >
                Add
               </Button>
              </div>
            </div>
            <p style={{color: 'red'}}>{this.state.ingredientErr}</p>
          </div>
          <Button type="primary" size="large" onClick={() => this.handleMealCreation()}>
            Create New Meal
          </Button>
        </div>
      </div>
    )
  }
}