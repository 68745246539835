import {
  REGISTER,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  AUTH_ERROR,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT
} from './constants';

export function register(name, email, password) {
  return {
    type: REGISTER,
    name,
    email,
    password
  };
}

export function registerSuccess(payload) {
  return {
    type: REGISTER_SUCCESS,
    payload,
  };
}

export function registerError() {
  return {
    type: REGISTER_ERROR,
  };
}

export function loadUser() {
  return {
    type: LOAD_USER
  };
}

export function loadUserSuccess(payload) {
  return {
    type: LOAD_USER_SUCCESS,
    payload
  };
}

export function loadUserError(err) {
  return {
    type: AUTH_ERROR,
    err
  };
}

export function loginUser(email, password) {
  return {
    type: LOGIN_USER,
    email,
    password
  };
}

export function loginUserSuccess(payload) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload
  };
}

export function loginUserError(err) {
  return {
    type: LOGIN_USER_ERROR,
    err
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}