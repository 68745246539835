import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userLoading = useSelector((state) => state.auth.userLoading);

  if (!isAuthenticated && !userLoading) {
    return <Navigate to='/login' replace />;
  }
  return children;
};

export default PrivateRoute;
