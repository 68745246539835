import React from 'react';
import { connect } from 'react-redux';
import { getOrdersDetail, deleteOrderDetail, updateOrderDetail } from '../ducks/OrdersDetail/actions';
import OrdersDetailPage from '../presentational/OrdersDetail';
import { Spin } from 'antd';

class OrdersDetail extends React.Component {
  
  componentDidMount(){
    this.props.getOrdersDetail();
  }

  render(){
    return (
      <div className='orders-page'>
        {this.props.ordersDetail ? 
        <OrdersDetailPage 
          {...this.props} 
        /> 
        : <Spin />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    ordersDetail: state.ordersDetail.ordersDetail
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOrdersDetail: () => dispatch(getOrdersDetail()),
    deleteOrderDetail: (id) => dispatch(deleteOrderDetail(id)),
    updateOrderDetail: (id, orderDetail) => dispatch(updateOrderDetail(id, orderDetail))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersDetail);