import {
  GET_INGREDIENTS,
  GET_INGREDIENTS_SUCCESS,
  GET_INGREDIENTS_ERROR
} from './constants';

export function getIngredients() {
  return {
    type: GET_INGREDIENTS
  };
}

export function getIngredientsSuccess(payload) {
  return {
    type: GET_INGREDIENTS_SUCCESS,
    payload
  };
}

export function getIngredientsError(err) {
  return {
    type: GET_INGREDIENTS_ERROR,
    err
  };
}