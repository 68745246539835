import React from 'react';
import { OneRepMaxCalculator } from '../components/OneRepMaxCalculator';

export function OneRepMax() {
  return (
    <div className='tools-page'>
      <OneRepMaxCalculator />
    </div>
  );
}
