import React, { Component } from 'react';
import { Progress, Button, Divider } from 'antd';
import { capitalize } from '../../helpers/utils';
import './index.css';

export default class Meal extends Component {
  renderLabel = (arr) => {
    return (
      <div className='flex column'>
        <p style={{ marginBottom: 0, lineHeight: '16px', fontWeight: '600' }}>
          {arr[1].toFixed(1)}
          {arr[0] !== 'calories' && 'g'}
        </p>
        <p style={{ fontSize: '14px', marginBottom: 0, lineHeight: '16px' }}>
          {capitalize(arr[0])}
        </p>
      </div>
    );
  };

  returnMacro = (meal) => {
    const progressColors = {
      '0%': '#2761f1',
      '100%': '#ff00a2',
    };
    return Object.entries(meal.nutrition).map((arr, index) => (
      <Progress
        key={index}
        className='progress-circle'
        type='circle'
        percent={100}
        strokeWidth={5}
        strokeColor={progressColors}
        format={() => this.renderLabel(arr)}
      />
    ));
  };

  renderMeals = (meal) => {
    if (
      (this.props.auth.isAuthenticated &&
        this.props.auth.user.role === 'admin') ||
      meal.isActive
    ) {
      return (
        <div key={meal._id} className='global-card'>
          <div className='flex space-between'>
            <h4 className='header title'>{meal.name}</h4>
            <p className='title'>${meal.price}</p>
          </div>
          <p className='subtitle mb-20'>{meal.description}</p>
          <div className='nutrition-info'>{this.returnMacro(meal)}</div>
          <div className='btn-container'>
            <Button
              className='manage-meal-qty-btn'
              onClick={() =>
                this.props.handleMealQty(this.props.meals, meal, 'subtract')
              }
              style={{ fontSize: '16px', borderRadius: '10px' }}
              type='secondary'
            >
              -
            </Button>
            <p className='title' style={{ padding: '0px 15px 0 15px' }}>
              {this.props.meal.qty || 0}
            </p>
            <Button
              className='manage-meal-qty-btn'
              onClick={() =>
                this.props.handleMealQty(this.props.meals, meal, 'add')
              }
              style={{ fontSize: '16px', borderRadius: '10px' }}
              type='secondary'
            >
              +
            </Button>
          </div>
          {this.props.auth &&
            this.props.auth.user &&
            this.props.auth.user.role === 'admin' && (
              <div className='meal-ing-container'>
                {meal.ingredients.map((ingredient) => (
                  <div className='meal-ing-grid label'>
                    <p style={{ fontWeight: 'bold' }}>{ingredient.name}</p>
                    <p>{ingredient.oz} oz.</p>
                    <p>{ingredient.grams} g.</p>
                    <p>{ingredient.lb} lb.</p>
                    <p>{ingredient.qty} qty.</p>
                  </div>
                ))}
              </div>
            )}

          {this.props.auth &&
            this.props.auth.user &&
            this.props.auth.user.role === 'admin' && (
              <div className='btn-container'>
                <Button
                  size={'large'}
                  type='primary'
                  onClick={() =>
                    this.props.updateMeal(meal._id, {
                      ...meal,
                      isActive: !meal.isActive,
                    })
                  }
                  className={`status-btn active ${
                    meal.isActive ? 'active' : 'inactive'
                  }`}
                >
                  {meal.isActive ? 'Active' : 'Inactive'}
                </Button>
              </div>
            )}
        </div>
      );
    }
  };

  render() {
    const { meal } = this.props;
    return this.renderMeals(meal);
  }
}
