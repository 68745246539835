import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'antd';
import { sendEmail } from '../../ducks/Global/actions';
import { SET_FORM_VALUES } from '../../ducks/Global/constants';
import { isValidEmail, addToMailChimp } from '../../helpers/utils';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import '../MacroNutrientCalculatorComponent/index.css';

export const LeadForm = ({
  goal,
  gender,
  results,
  macroPreference,
  percentageError,
  formInfo,
  step,
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const form = useSelector((state) => state.global.form);
  const sendMacroEmailLoading = useSelector(
    (state) => state.global.sendEmailLoading
  );
  const sendMacroEmailError = useSelector(
    (state) => state.global.sendEmailError
  );
  const successMessage = useSelector((state) => state.global.successMessage);
  const { t } = useTranslation();

  const handleNameChange = (e, field) => {
    const updatedForm = { ...form, [field]: e.target.value.toLowerCase() };
    dispatch({ type: SET_FORM_VALUES, payload: updatedForm });
  };

  const handleFormSubmit = () => {
    let emailData = {
      url: formInfo.url,
      form: form,
      templateId: formInfo.templateId,
      results: results || null,
      goal: goal || null,
      gender: gender || null,
      subject: formInfo.subject,
      source: formInfo.source,
    };
    if (!form) {
      setError(t('formErrors.fillFields'));
    } else if (!form?.name) {
      setError(t('formErrors.enterName'));
    } else if (!form?.email) {
      setError(t('formErrors.enterEmail'));
    } else if (form?.email && !isValidEmail(form?.email)) {
      setError(t('formErrors.invalidEmail'));
    } else {
      addToMailChimp(form, formInfo.tags);
      dispatch(sendEmail(emailData, t('form.successMessage')));
    }
  };

  return (
    <div className='global-card light-background'>
      {successMessage ? (
        <div className='grid justify-center'>
          <CheckCircleOutlined
            style={{ fontSize: '50px', color: '#4EF704', marginBottom: '10px' }}
          />
          <h4 style={{ textAlign: 'center' }}>{successMessage}</h4>
        </div>
      ) : (
        <>
          {/* {step && <p className='step-text'>STEP 3</p>} */}
          <div className='two-column align-center'>
            <div>
              <p className='small-title mb-10'>{formInfo.title}</p>
              <p>{formInfo.subtitle}</p>
            </div>

            <div>
              <Input
                style={{ height: '50px', marginBottom: '10px' }}
                placeholder='Name'
                onChange={(e) => handleNameChange(e, 'name')}
                width='100%'
              />
              <Input
                style={{ height: '50px', marginBottom: '10px' }}
                placeholder='Email'
                onChange={(e) => handleNameChange(e, 'email')}
                width='100%'
              />
              <div className='grid'>
                <Button
                  className='mb-5'
                  type='primary'
                  size='large'
                  onClick={() => handleFormSubmit()}
                  loading={sendMacroEmailLoading}
                  disabled={percentageError && macroPreference === 'custom'}
                  style={{ width: '100%' }}
                >
                  {formInfo.buttonText}
                </Button>
              </div>
            </div>

            <div>
              {/* Add Checkbox if needed */}
              {error && (
                <p
                  style={{
                    color: 'red',
                    marginBottom: '10px',
                    fontSize: '14px',
                  }}
                >
                  {error}
                </p>
              )}
              {sendMacroEmailError && (
                <p
                  style={{
                    color: 'red',
                    marginBottom: '10px',
                    fontSize: '14px',
                  }}
                >
                  {sendMacroEmailError}
                </p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
