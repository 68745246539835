import { all } from 'redux-saga/effects';
import mealsSaga from './ducks/Meals/saga';
import authSaga from './ducks/Auth/saga';
import ordersSaga from './ducks/Orders/saga';
import ordersDetailSaga from './ducks/OrdersDetail/saga';
import ingredientsSaga from './ducks/Ingredients/saga';
// import toolsSaga from './ducks/Tools/saga';
import globalSaga from './ducks/Global/saga';

export default function* rootSaga() {
  yield all([
    mealsSaga(),
    authSaga(),
    ordersSaga(),
    ordersDetailSaga(),
    ingredientsSaga(),
    // toolsSaga(),
    globalSaga()
  ])
}