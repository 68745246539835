import React, { Component } from 'react';
import './index.css';
import { OrdersDetailCard } from './ordersDetailCard';

export default class OrdersDetailPage extends Component {
  render() {
    return (
      <div className='orders-detail'>
        {this.props.ordersDetail && this.props.ordersDetail.map(orderDetail => 
        <OrdersDetailCard 
          orderDetail={orderDetail} 
          deleteOrderDetail={this.props.deleteOrderDetail}
          updateOrderDetail={this.props.updateOrderDetail}
        />)}
      </div>
    )
  }
}