export const legal = {
  disclaimer: (
    <div>
      <p className='title mb-20'>Disclaimer</p>
      <p className='mb-20'>
        This information is provided "as is" without warranty of any kind,
        either expressed or implied, including, but not limited to, the implied
        warranties of merchantability, fitness for a particular purpose, or
        non-infringement. In no event shall All health tools be liable for any
        special, incidental, indirect, or consequential damages of any kind, or
        any damages whatsoever resulting from loss of use, data, or profits,
        whether or not advised of the possibility of damage under any theory of
        liability, arising out of or in connection with the use or performance
        of this information. This information could include technical
        inaccuracies or typographical errors. Changes may be added periodically
        to the information contained in any of our web pages or sites.
      </p>
      <p className='mb-20'>
        Because this website deals with issues of a health-related nature, we
        hereby state that All health tools does not offer medical advice.
        Readers should seek medical advice from a licensed physician and not
        rely solely on information they may gather from secondary sources such
        as the Internet. All health tools cannot be held responsible for the
        validity of the information contained in any reference noted herein, nor
        for the misuse of information or any adverse effects from the use of any
        stated materials presented herein.
      </p>
      <p className='mb-20'>
        This content is intended to offer factual, up-to-date information on the
        subjects discussed, but in no way should it be regarded as a complete
        analysis of these subjects. Physicians and other medical professionals
        should be consulted prior to implementing any items presented. No party
        associated in any way with this site or its content assumes liability
        for any loss or damage resulting from the use or misuse of any
        information contained herein or for any errors or omissions.
      </p>
      <p className='mb-20'>
        To try and bring you offers that are of interest to you, we have
        relationships with other companies that we allow to place ads on our Web
        pages. As a result of your visit to our site, ad server companies may
        collect information such as your domain type, your IP address, and
        clickstream information. An advertiser may also display an advertisement
        that contains a cookie. These cookies are not sent from our site, and we
        have no access to the information in these cookies.
      </p>
      <p className='mb-20'>
        <strong>Important Note:</strong> Results in fitness and nutrition
        programs may vary from person to person. Individual results depend on
        various factors including, but not limited to, adherence to the program,
        genetics, lifestyle, and overall health. It is recommended to consult
        with a qualified fitness or healthcare professional before starting any
        fitness or nutrition program.
      </p>
      <p className='mb-20'>
        If you have any further questions or concerns, please contact us at{' '}
        <a href='mailto:contact@alvarofitness.com'>contact@alvarofitness.com</a>
        .
      </p>
    </div>
  ),
  privacyPolicy: (
    <div>
      <p className='title mb-20'>Privacy Policy</p>
      <p className='subtitle mb-20'>
        We are glad of your interest in Alvaro Fitness. We have created this
        privacy statement in order to demonstrate our firm commitment to
        privacy.
      </p>

      <p className='mb-20'>
        {' '}
        For each visitor to our Web Page, our Web Server automatically logs
        statistical information such as the user's IP address, browser type, and
        the site where the user came from. None of this information can be used
        to personally identify an individual user. The information stored on our
        server may be used to provide statistics about the usage of this site,
        for the purpose of improving this site and to provide statistical
        information to advertisers. Only aggregated information is given, and
        the log file itself is not available to the public.
      </p>

      <p className='mb-20'>
        We use cookies to store information, such as your personal preferences
        when you visit our site. This could include only showing you a popup
        once in your visit, or retrieving your email in order to send detailed
        reports or additional information regarding our products and services.
        To try and bring you offers that are of interest to you, we have
        relationships with other companies that we allow to place ads on our Web
        pages. As a result of your visit to our site, ad server companies may
        collect information such as your domain type, your IP address, and
        clickstream information. An advertiser may also display an advertisement
        that contains a cookie. These cookies are not sent from our site and we
        have no access to the information in these cookies.
      </p>

      <p className='mb-20'>
        Google's use of the DART cookie enables it and its partners to serve ads
        to you based on your visit to our website and other sites on the
        internet. This cookie is used to serve ads specific to you and your
        interests ("interest-based targeting"). The ads served will be targeted
        based on your previous browsing history (For example, if you have been
        viewing sites about visiting Colorado, you may see Colorado hotel
        advertisements when viewing a non-related site, such as on a site about
        clothes). DART uses "non personally identifiable information". It does
        NOT track personal information about you, such as your name, email
        address, physical address, telephone number, social security numbers,
        bank account numbers, or credit card numbers. You can opt-out of this ad
        serving on all sites using this advertising by visiting the advertising
        opt-out page. This site contains links to other sites.
      </p>

      <p className='mb-20'>
        All health tools is not responsible for the privacy practices or the
        content of such Web Sites. From time to time, we may use customer
        information for new, unanticipated uses not previously disclosed in our
        privacy notice. If our information practices change at some time in the
        future we will post the policy changes to our Web site to notify you of
        these changes and provide you with the ability to opt-out of these new
        uses. If you are concerned about how your information is used, you
        should check back at our Web site periodically. If you feel this site is
        not following its stated privacy policy or if you have any further
        questions, you may contact us at{' '}
        <a href='mailto:contact@alvarofitness.com'>contact@alvarofitness.com</a>
        .
      </p>
    </div>
  ),
};
