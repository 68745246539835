import React, { Component } from 'react'
import { Divider, Button, Input, Modal, DatePicker } from 'antd';
import { validateEmail } from '../../helpers/utils';
import moment from 'moment';
import './index.css';

export default class OrderSummary extends Component {
  state={customer: {}, isValidEmail: '', pickUpDate: '' , error: ''}

  handleCreateOrder = () => {
  let pickUpDate = moment(this.state.pickUpDate).format('l');
  if(this.state.isValidEmail && this.state.customer.name !== '' && this.state.customer.email !== ''){
      this.props.createOrder(this.state.customer, this.props.meals, this.props.orderQty, this.props.orderTotal, pickUpDate);
      !this.props.createOrderLoading && this.props.handleModal();
      this.setState({error: ''})
    } else {
      this.setState({error: 'Name and email fields are required.'})
    }
  }

  render() {
    return (
      <div className='order-summary'>
       <h2 className='title'>Order Summary</h2>
       <div className='order-container'>
        {this.props.meals.map(meal => meal.qty > 0 && 
        <>
          <div key={meal._id} className='flex space-between align-center'>
            <p className='subtitle'>{meal.name}</p>
            <div className='flex'>
              <p className='subtitle' style={{padding: '0 5px'}}>{meal.qty}</p>
              <p className='subtitle' style={{padding: '0 5px'}}>@</p>
              <p className='subtitle' style={{padding: '0 5px'}}>${meal.price}</p>
            </div>
          </div>
          <Divider style={{margin: '5px'}}/>
          </>
        )}
       </div>
       {this.props.orderTotal !== 0 ?
       <>
       <div className='flex space-between'>
          <p className='subtitle' style={{fontWeight: '600'}}>Total</p>
          <div className='flex'>
            <p className='subtitle' style={{padding: '0 5px', fontWeight: '600'}}>{this.props.orderQty}</p>
            <p className='subtitle' style={{padding: '0 5px', fontWeight: '600'}}>@</p>
            <p className='subtitle' style={{padding: '0 5px', fontWeight: '600'}}>${this.props.orderTotal}</p>
          </div>
       </div>
      
       <div className='flex space-between'>
        <div></div>
        <Button type='primary' style={{marginTop: '15px'}} onClick={() => this.props.handleModal()}>Place order</Button>
       </div>
       </> :
       <p>No meals added.</p>}
      
        <Modal
          open={this.props.isVisible}
          footer={null}
          closable={false}
          centered
          onCancel={() => this.props.handleModal()}
        >
          <div className='email-form-container'>
            <div>
              <p style={{fontWeight: '500'}}>Full Name*</p>
              <Input 
                placeholder={'Full Name'} 
                onChange={(e) => this.setState({customer: {...this.state.customer, name: e.target.value}})}
              />
            </div>
            <div>
              <p  style={{fontWeight: '500'}}>Email*</p>
              <Input 
                placeholder={'Email'} 
                onChange={(e) => this.setState({customer: {...this.state.customer, email: e.target.value}, isValidEmail: validateEmail(e.target.value)})}
              />
            </div>
            <div>
              <p  style={{fontWeight: '500'}}>Pick Up Date</p>
              <DatePicker 
                style={{ width: "100%" }}
                format={"MMM Do, YY"}
                disabledDate={(current) => {
                  return current && current < moment().endOf("day") 
                }}
                value={this.state.pickUpDate}
                onChange={(e) => this.setState({pickUpDate: e})}
                allowClear={false}
                showToday={false}
              />
            </div>
            <Button 
              type='primary' 
              onClick={() => this.handleCreateOrder()}
              loading={this.props.createOrderLoading}
            >
              Confirm Order
            </Button>
            {this.state.error && <p style={{color: 'red'}}>{this.state.error}</p>}
        </div>
        </Modal>
      </div>
    )
  }
}