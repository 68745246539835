import React, { Component } from 'react'
import OrderSummary from './orderSummary';
import Meals from './meals';
import AdminActions from '../../components/AdminActions';
import './index.css';

class HomePage extends Component {
  render() {
    return (
      <div className='home-page'>
        {this.props.auth && this.props.auth.user && this.props.auth.user.role === 'admin' && 
        <AdminActions {...this.props} /> }
        <OrderSummary {...this.props} isVisible={this.props.isVisible} />
        {this.props.meals && <Meals {...this.props} meals={this.props.meals}/>}
      </div>
    )
  }
}

export default HomePage;