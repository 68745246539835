import {
  GET_ORDERS_DETAIL,
  GET_ORDERS_DETAIL_SUCCESS,
  GET_ORDERS_DETAIL_ERROR,
  CREATE_ORDER_DETAIL,
  CREATE_ORDER_DETAIL_SUCCESS,
  CREATE_ORDER_DETAIL_ERROR,
  UPDATE_ORDER_DETAIL,
  UPDATE_ORDER_DETAIL_SUCCESS,
  UPDATE_ORDER_DETAIL_ERROR,
  DELETE_ORDER_DETAIL,
  DELETE_ORDER_DETAIL_SUCCESS,
  RESET_CREATE_ORDER_DETAIL_SUCCESS
  } from './constants';
  
  const initialState = {
    ordersDetail: null,
    ordersDetailLoading: false,
    ordersDetailError: '',
    createOrderDetailLoading: false,
    createOrderDetailErr: null,
    deleteOrderDetailLoading: null,
    deleteOrderDetailErr: null,
    createOrderDetailSuccess: false
  };
  
  function ordersDetail(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_ORDERS_DETAIL:
        return {
          ...state,
          ordersDetail: null,
          ordersDetailLoading: true,
        };
      case GET_ORDERS_DETAIL_SUCCESS:
        return {
          ...state,
          ordersDetail: payload,
          ordersDetailLoading: false
        };
      case GET_ORDERS_DETAIL_ERROR:
        return {
          ...state,
          ordersDetail: null,
          ordersDetailLoading: false,
          ordersDetailError: payload
        };
      case CREATE_ORDER_DETAIL:
        return {
          ...state,
          ordersDetail: state.ordersDetail,
          createOrderDetailLoading: true,
        };
      case CREATE_ORDER_DETAIL_SUCCESS:
        return {
          ...state,
          ordersDetail: [...state.ordersDetail, payload],
          createOrderDetailLoading: false,
          createOrderDetailSuccess: true
        };
      case CREATE_ORDER_DETAIL_ERROR:
        return {
          ...state,
          ordersDetail: state.ordersDetail,
          createOrderDetailLoading: false,
          createOrderDetailErr: payload
        };
      case UPDATE_ORDER_DETAIL:
        return {
          ...state,
          ordersDetail: state.ordersDetail,
          createOrderDetailLoading: true,
        };
      case UPDATE_ORDER_DETAIL_SUCCESS:
        return {
          ...state,
          ordersDetail: state.ordersDetail.map(orderDetail => (orderDetail._id === payload._id) ? payload : orderDetail),
          createOrderDetailLoading: false
        };
      case UPDATE_ORDER_DETAIL_ERROR:
        return {
          ...state,
          ordersDetail: state.ordersDetail,
          createOrderDetailLoading: false,
          createOrderDetailErr: payload
        };
      case DELETE_ORDER_DETAIL:
        return {
          ...state,
          deleteOrderDetailLoading: true
        };
      case DELETE_ORDER_DETAIL_SUCCESS:
        return {
          ...state,
          ordersDetail: state.ordersDetail.filter(orderDetail => orderDetail._id !==  action.id),
          deleteOrderDetailLoading: false
        };
      case RESET_CREATE_ORDER_DETAIL_SUCCESS:
        return {
          ...state,
          createOrderDetailSuccess: false
        };
      default:
        return state;
    }
  }
  
  export default ordersDetail;