import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Divider } from 'antd';

export const Ingredient = (props) => {
  return(
    <>
      <div className='ingredient-row'>
        <p>{props.ingredient.name}</p>
        <p>{props.ingredient.grams} grams</p>
        <p>{props.ingredient.oz} oz</p>
        <p>{props.ingredient.qty} qty</p>
        <DeleteOutlined onClick={() => props.removeIngredient(props.ingredient.name)}/>
      </div>
      <Divider />
    </>
  )
}