export const GET_ORDERS_DETAIL = 'GET_ORDERS_DETAIL';
export const GET_ORDERS_DETAIL_SUCCESS = 'GET_ORDERS_DETAIL_SUCCESS';
export const GET_ORDERS_DETAIL_ERROR = 'GET_ORDERS_DETAIL_ERROR';

export const CREATE_ORDER_DETAIL = 'CREATE_ORDER_DETAIL';
export const CREATE_ORDER_DETAIL_SUCCESS = 'CREATE_ORDER_DETAIL_SUCCESS';
export const CREATE_ORDER_DETAIL_ERROR = 'CREATE_ORDER_DETAIL_ERROR';

export const UPDATE_ORDER_DETAIL = 'UPDATE_ORDER_DETAIL';
export const UPDATE_ORDER_DETAIL_SUCCESS = 'UPDATE_ORDER_DETAIL_SUCCESS';
export const UPDATE_ORDER_DETAIL_ERROR = 'UPDATE_ORDER_DETAIL_ERROR';

export const DELETE_ORDER_DETAIL = 'DELETE_ORDER_DETAIL';
export const DELETE_ORDER_DETAIL_SUCCESS = 'DELETE_ORDER_DETAIL_SUCCESS';

export const RESET_CREATE_ORDER_DETAIL_SUCCESS = 'RESET_CREATE_ORDER_DETAIL_SUCCESS';
