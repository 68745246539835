import { put, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import {
  GET_ORDERS_DETAIL,
  CREATE_ORDER_DETAIL,
  DELETE_ORDER_DETAIL,
  UPDATE_ORDER_DETAIL,
} from './constants';
import {
  getOrdersDetailSuccess,
  getOrdersDetailError,
  createOrderDetailSuccess,
  createOrderDetailError,
  deleteOrderDetailSuccess,
  updateOrderDetailSuccess,
  updateOrderDetailError,
} from './actions';

export function* getOrdersDetail() {
  try {
    const payload = yield call(axios.get, '/meals/api/orderDetail');
    yield put(getOrdersDetailSuccess(payload.data));
  } catch (err) {
    console.log(err);
    yield put(getOrdersDetailError(err));
  }
}

export function* createOrderDetail({
  ingredients,
  totalMeals,
  totalCost,
  orderFromTo,
}) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    ingredients,
    totalMeals,
    totalCost,
    orderFromTo,
  });

  try {
    const payload = yield call(
      axios.post,
      '/meals/api/orderDetail',
      body,
      config
    );
    yield put(createOrderDetailSuccess(payload.data));
  } catch (err) {
    console.log(err);
    yield put(createOrderDetailError(err));
  }
}

export function* updateOrderDetail({ id, orderDetail }) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  orderDetail.isChecked = !orderDetail.isChecked;

  const body = JSON.stringify(orderDetail);

  try {
    const payload = yield call(
      axios.post,
      `${`/meals/api/orderDetail/${id}`}`,
      body,
      config
    );
    yield put(updateOrderDetailSuccess(payload.data));
  } catch (err) {
    const errors = err.response.data.errors;
    console.log(errors);
    console.log(err);
    yield put(updateOrderDetailError(errors));
  }
}

export function* deleteOrderDetail({ id }) {
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'DELETE',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json',
      },
    };
    yield call(axios.delete, `/meals/api/orderDetail/${id}`, config);
    yield put(deleteOrderDetailSuccess(id));
  } catch (err) {
    const error = yield err.json();
    console.log(error);
  }
}

export default function* ordersDetailSaga() {
  yield takeLatest(GET_ORDERS_DETAIL, getOrdersDetail);
  yield takeLatest(CREATE_ORDER_DETAIL, createOrderDetail);
  yield takeLatest(DELETE_ORDER_DETAIL, deleteOrderDetail);
  yield takeLatest(UPDATE_ORDER_DETAIL, updateOrderDetail);
}
