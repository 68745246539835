export const proteins = [
  {
    name: 'Chicken Breast',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 7,
      carbs: 0,
      fats: 0.75,
      calories: 35,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
  },
  {
    name: 'Lean Ground Turkey',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 7,
      carbs: 0,
      fats: 1,
      calories: 37.5,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
  },
  {
    name: 'Lean Ground Beef',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6,
      carbs: 0,
      fats: 2.5,
      calories: 50,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
  },
  {
    name: 'Ground Bison',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6,
      carbs: 0,
      fats: 1.75,
      calories: 41.5,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
  },
  {
    name: 'Lean Steak',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6.25,
      carbs: 0,
      fats: 2,
      calories: 45,
    },
    recommendedFor: ['dinner'],
    dietType: ['standard'],
  },
  {
    name: 'Salmon',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6.25,
      carbs: 0,
      fats: 3.25,
      calories: 50,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
  },
  {
    name: 'Shrimp',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6,
      carbs: 0,
      fats: 0.25,
      calories: 25,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard'],
  },
  {
    name: 'Tofu',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 2.25,
      carbs: 0.5,
      fats: 1.25,
      calories: 20,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['vegetarian'],
  },
  {
    name: 'Greek Yogurt',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 2.5,
      carbs: 1,
      fats: 0,
      calories: 12.5,
    },
    recommendedFor: ['breakfast', 'snack'],
    dietType: ['standard', 'vegetarian'],
  },
  {
    name: 'Cottage Cheese',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 3.5,
      carbs: 1.25,
      fats: 0.5,
      calories: 22.5,
    },
    recommendedFor: ['breakfast', 'snack'],
    dietType: ['standard', 'vegetarian'],
  },
  {
    name: 'Egg Whites',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 3.5,
      carbs: 0,
      fats: 0,
      calories: 16.5,
    },
    recommendedFor: ['breakfast'],
    dietType: ['standard', 'vegetarian'],
  },
  {
    name: 'Eggs',
    servingSize: 1,
    servingType: 'unit',
    macros: {
      protein: 6,
      carbs: 0,
      fats: 5,
      calories: 70,
    },
    recommendedFor: ['breakfast'],
    dietType: ['standard', 'vegetarian'],
  },
  {
    name: 'Tempeh',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 5.25,
      carbs: 3.25,
      fats: 2.75,
      calories: 50,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['vegetarian'],
  },
  {
    name: 'Seitan',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6.25,
      carbs: 1,
      fats: 0.5,
      calories: 35,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['vegetarian'],
  },
  {
    name: 'Whey Protein Isolate',
    servingSize: 1,
    servingType: 'scoop (30g)',
    macros: {
      protein: 25,
      carbs: 2,
      fats: 1,
      calories: 120,
    },
    recommendedFor: ['snack', 'post-workout'],
    dietType: ['standard'],
  },
  {
    name: 'Casein Protein',
    servingSize: 1,
    servingType: 'scoop',
    macros: {
      protein: 24,
      carbs: 3,
      fats: 1,
      calories: 110,
    },
    recommendedFor: ['snack', 'pre-bed'],
    dietType: ['standard'],
  },
  {
    name: 'Pea Protein',
    servingSize: 1,
    servingType: 'scoop',
    macros: {
      protein: 24,
      carbs: 3,
      fats: 2,
      calories: 120,
    },
    recommendedFor: ['snack', 'post-workout'],
    dietType: ['vegetarian'],
  },
];

export const carbs = [
  {
    name: 'Sweet Potato',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.5,
      carbs: 6.5,
      fats: 0,
      calories: 26,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Brown Rice',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1,
      carbs: 7,
      fats: 0.2,
      calories: 32,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Quinoa',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1.25,
      carbs: 6,
      fats: 0.5,
      calories: 28,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Oats (Rolled or Steel-Cut)',
    servingSize: 0.5,
    servingType: 'cup',
    macros: {
      protein: 5,
      carbs: 27,
      fats: 2.5,
      calories: 150,
    },
    recommendedFor: ['breakfast'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Whole Wheat Pasta',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 3.5,
      carbs: 20,
      fats: 0.8,
      calories: 99,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Lentils',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 2.5,
      carbs: 6,
      fats: 0.1,
      calories: 26,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Couscous',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1.25,
      carbs: 6.5,
      fats: 0.1,
      calories: 30,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Whole Wheat Bread',
    servingSize: 1,
    servingType: 'slice',
    macros: {
      protein: 3,
      carbs: 12,
      fats: 1,
      calories: 70,
    },
    recommendedFor: ['breakfast'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
];

export const fats = [
  {
    name: 'Olive Oil',
    servingSize: 1,
    servingType: 'tbsp',
    macros: {
      protein: 0,
      carbs: 0,
      fats: 14,
      calories: 120,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'], // Suitable for all diets
  },
  {
    name: 'Avocado',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.5,
      carbs: 3,
      fats: 4.5,
      calories: 45,
    },
    recommendedFor: ['breakfast', 'lunch', 'snack'],
    dietType: ['standard', 'vegetarian', 'vegan'], // Suitable for all diets
  },
  {
    name: 'Almonds',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6,
      carbs: 6,
      fats: 14,
      calories: 160,
    },
    recommendedFor: ['snack'],
    dietType: ['standard', 'vegetarian', 'vegan'], // Suitable for all diets
  },
  {
    name: 'Walnuts',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 4.3,
      carbs: 4,
      fats: 18,
      calories: 185,
    },
    recommendedFor: ['snack'],
    dietType: ['standard', 'vegetarian', 'vegan'], // Suitable for all diets
  },
  {
    name: 'Peanut Butter',
    servingSize: 1,
    servingType: 'tbsp',
    macros: {
      protein: 3.5,
      carbs: 1.5,
      fats: 8,
      calories: 94,
    },
    recommendedFor: ['breakfast', 'snack'],
    dietType: ['standard', 'vegetarian', 'vegan'], // Suitable for all diets
  },
  {
    name: 'Chia Seeds',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 5,
      carbs: 12,
      fats: 9,
      calories: 137,
    },
    recommendedFor: ['breakfast', 'snack'],
    dietType: ['standard', 'vegetarian', 'vegan'], // Suitable for all diets
  },
  {
    name: 'Cashews',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 5,
      carbs: 9,
      fats: 12,
      calories: 155,
    },
    recommendedFor: ['snack'],
    dietType: ['standard', 'vegetarian', 'vegan'], // Suitable for all diets
  },
  {
    name: 'Coconut Oil',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0,
      carbs: 0,
      fats: 14,
      calories: 120,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'], // Suitable for all diets
  },
  {
    name: 'Pistachios',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 6,
      carbs: 8,
      fats: 12,
      calories: 160,
    },
    recommendedFor: ['snack'],
    dietType: ['standard', 'vegetarian', 'vegan'], // Suitable for all diets
  },
];

export const greenVegetables = [
  {
    name: 'Spinach',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1,
      carbs: 1,
      fats: 0,
      calories: 7,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Kale',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.9,
      carbs: 1.4,
      fats: 0.1,
      calories: 9,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Broccoli',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1,
      carbs: 2,
      fats: 0,
      calories: 10,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Green Beans',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.8,
      carbs: 2.7,
      fats: 0,
      calories: 10,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Zucchini',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.4,
      carbs: 1.2,
      fats: 0,
      calories: 5,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Cucumber',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.3,
      carbs: 1.1,
      fats: 0,
      calories: 4,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Asparagus',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.9,
      carbs: 1.3,
      fats: 0,
      calories: 6,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Brussels Sprouts',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 1.3,
      carbs: 2.5,
      fats: 0.1,
      calories: 11,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Romaine Lettuce',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.5,
      carbs: 1,
      fats: 0,
      calories: 5,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Collard Greens',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.9,
      carbs: 2,
      fats: 0.2,
      calories: 11,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Swiss Chard',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.7,
      carbs: 1.3,
      fats: 0,
      calories: 7,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
  {
    name: 'Arugula',
    servingSize: 1,
    servingType: 'oz',
    macros: {
      protein: 0.3,
      carbs: 0.6,
      fats: 0,
      calories: 5,
    },
    recommendedFor: ['lunch', 'dinner'],
    dietType: ['standard', 'vegetarian', 'vegan'],
  },
];
