import React from 'react';
import { useNavigate } from 'react-router-dom';
import { tools } from '../data/tools';

export const Tools = () => {
  const navigate = useNavigate();

  const renderToolsCards = () => {
    return (
      <div className='tools-card-container'>
        {tools.map((tool, i) => (
          <div key={i} className='flex justify-center'>
            <div
              onClick={() => {
                navigate(tool.route);
                // this.props.handleIframeButtonClick(tool);
              }}
              key={tool.key}
              className='global-card'
              style={{
                cursor: 'pointer',
                marginTop: '30px',
                width: '100%',
                maxWidth: '850px',
                marginTop: '0px',
              }}
            >
              <img style={{ height: '80px' }} alt='Tool' src={tool.cardImg} />
              <div style={{ marginTop: '20px' }}>
                <h3 style={{ maxWidth: '100%', width: '100%!important' }}>
                  {tool.name}
                </h3>
                <p>{tool.cardSubtitle}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return <div className='tools-page'>{renderToolsCards()}</div>;
};
