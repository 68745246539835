import {
  GET_ORDERS_DETAIL,
  GET_ORDERS_DETAIL_SUCCESS,
  GET_ORDERS_DETAIL_ERROR,
  CREATE_ORDER_DETAIL,
  CREATE_ORDER_DETAIL_SUCCESS,
  CREATE_ORDER_DETAIL_ERROR,
  DELETE_ORDER_DETAIL,
  DELETE_ORDER_DETAIL_SUCCESS,
  UPDATE_ORDER_DETAIL,
  UPDATE_ORDER_DETAIL_SUCCESS,
  UPDATE_ORDER_DETAIL_ERROR,
  RESET_CREATE_ORDER_DETAIL_SUCCESS
} from './constants';

export function getOrdersDetail() {
  return {
    type: GET_ORDERS_DETAIL
  };
}

export function getOrdersDetailSuccess(payload) {
  return {
    type: GET_ORDERS_DETAIL_SUCCESS,
    payload
  };
}

export function getOrdersDetailError(err) {
  return {
    type: GET_ORDERS_DETAIL_ERROR,
    err
  };
}

export function createOrderDetail(ingredients, totalMeals, totalCost, orderFromTo) {
  return {
    type: CREATE_ORDER_DETAIL,
    ingredients, 
    totalMeals, 
    totalCost, 
    orderFromTo
  };
}

export function createOrderDetailSuccess(payload) {
  return {
    type: CREATE_ORDER_DETAIL_SUCCESS,
    payload
  };
}

export function createOrderDetailError(err) {
  return {
    type: CREATE_ORDER_DETAIL_ERROR,
    err
  };
}

export function updateOrderDetail(id, orderDetail) {
  return {
    type: UPDATE_ORDER_DETAIL,
    id,
    orderDetail
  };
}

export function updateOrderDetailSuccess(payload) {
  return {
    type: UPDATE_ORDER_DETAIL_SUCCESS,
    payload
  };
}

export function updateOrderDetailError(err) {
  return {
    type: UPDATE_ORDER_DETAIL_ERROR,
    err
  };
}

export function deleteOrderDetail(id) {
  return {
    type: DELETE_ORDER_DETAIL,
    id
  };
}

export function deleteOrderDetailSuccess(id) {
  return {
    type: DELETE_ORDER_DETAIL_SUCCESS,
    id
  };
}

export function resetCreateOrderDetailSuccess(){
  return {
    type: RESET_CREATE_ORDER_DETAIL_SUCCESS
  }
}
