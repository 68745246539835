import React, { Component } from 'react';
import { connect } from 'react-redux';
import HomePage from '../presentational/HomePage';
import { getMeals } from '../ducks/Meals/actions';
import { Spin } from 'antd';
import {
  handleMealQty,
  deactivateAll,
  updateMeal,
} from '../ducks/Meals/actions';
import { createOrder, getOrders } from '../ducks/Orders/actions';
import withNavigateHook from '../withNavigateHook';
import 'react-toastify/dist/ReactToastify.css';

class Home extends Component {
  state = { isVisible: false };

  componentDidMount() {
    this.props.getMeals();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.orders && prevProps.orders.length) <
      (this.props.orders && this.props.orders.length)
    ) {
      this.setState({ isVisible: false });
      this.props.navigation(`/confirmation/${this.props.createdOrder._id}`);
    }
  }

  handleModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  render() {
    return (
      <>
        {this.props.meals ? (
          <HomePage
            {...this.props}
            isVisible={this.state.isVisible}
            handleModal={this.handleModal}
          />
        ) : (
          <Spin />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    meals: state.meals.meals,
    orderTotal: state.meals.orderTotal,
    orderQty: state.meals.orderQty,
    createOrderLoading: state.meals.createOrderLoading,
    createOrderErr: state.meals.createOrderErr,
    auth: state.auth,
    orders: state.orders.orders,
    mealsLoading: state.meals.mealsLoading,
    createdOrder: state.orders.createdOrder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMeals: () => dispatch(getMeals()),
    handleMealQty: (meals, mealSelected, btn) =>
      dispatch(handleMealQty(meals, mealSelected, btn)),
    createOrder: (customer, meals, orderQty, orderTotal, pickUpDate) =>
      dispatch(createOrder(customer, meals, orderQty, orderTotal, pickUpDate)),
    getOrders: () => dispatch(getOrders()),
    deactivateAll: () => dispatch(deactivateAll()),
    updateMeal: (id, meal) => dispatch(updateMeal(id, meal)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(Home));
