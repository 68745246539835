import React, { useState, useEffect } from 'react';
import './index.css';

export const CountdownTimer = ({ expiryTimestamp }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Calculate time left and update it every second
  function calculateTimeLeft() {
    const currentTime = Date.now();
    const timeDifference = expiryTimestamp - currentTime;

    if (timeDifference <= 0) {
      // Timer has expired, handle this condition as needed
      // For example, display a message or take some action
      return 'Timer expired';
    }

    const totalSeconds = Math.floor(timeDifference / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return { hours, minutes, seconds };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      setTimeLeft(timeLeft);

      // Store the expiry timestamp in localStorage
      localStorage.setItem('expiryTimestamp', expiryTimestamp.toString());

      if (timeLeft === 'Timer expired') {
        // Timer has expired, clear the timer
        clearInterval(timer);

        // You can also take any necessary action when the timer expires
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [expiryTimestamp]);

  return (
    <div className='timer'>
      {timeLeft === 'Timer expired' ? null : (
        <div className='timer-count'>
          <p className='timer-text'>{`${timeLeft.hours} hours ${timeLeft.minutes} minutes ${timeLeft.seconds} seconds left!`}</p>
        </div>
      )}
    </div>
  );
};
