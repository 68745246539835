export const GET_MEALS = 'GET_MEALS';
export const GET_MEALS_SUCCESS = 'GET_MEALS_SUCCESS';
export const GET_MEALS_ERROR = 'GET_MEALS_ERROR';

export const CREATE_MEAL = 'CREATE_MEAL';
export const CREATE_MEAL_SUCCESS = 'CREATE_MEAL_SUCCESS';
export const CREATE_MEAL_ERROR = 'CREATE_MEAL_ERROR';

export const UPDATE_MEAL = 'UPDATE_MEAL';
export const UPDATE_MEAL_SUCCESS = 'UPDATE_MEAL_SUCCESS';
export const UPDATE_MEAL_ERROR = 'UPDATE_MEAL_ERROR';

export const HANDLE_MEAL_QTY = 'HANDLE_MEAL_QTY';

export const DEACTIVATE_ALL = 'DEACTIVATE_ALL';
export const DEACTIVATE_ALL_SUCCESS = 'DEACTIVATE_ALL_SUCCESS';