import {
GET_MEALS,
GET_MEALS_SUCCESS,
GET_MEALS_ERROR,
UPDATE_MEAL_SUCCESS,
UPDATE_MEAL_ERROR,
CREATE_MEAL,
CREATE_MEAL_SUCCESS,
CREATE_MEAL_ERROR,
HANDLE_MEAL_QTY
} from './constants';

const initialState = {
  meals: null,
  mealsLoading: true,
  mealsError: null,
  orderTotal: 0,
  orderQty: 0,
  createMealsLoading: false,
  createMealError: false
};

function meals(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MEALS:
      return {
        ...state,
        meals: null,
        mealsLoading: true,
      };
    case GET_MEALS_SUCCESS:
      payload.forEach(meal => meal.qty = 0)
      return {
        ...state,
        meals: payload,
        mealsLoading: false
      };
    case GET_MEALS_ERROR:
      return {
        ...state,
        meals: null,
        mealsLoading: false,
        mealsError: payload
      };
    case CREATE_MEAL:
      return {
        ...state,
        meals: state.meals,
        createMealsLoading: true,
        createMealError: false
      };
    case CREATE_MEAL_SUCCESS:
      return {
        ...state,
        meals: [...state.meals, payload],
        createMealsLoading: false,
        createMealError: false
      };
    case CREATE_MEAL_ERROR:
      return {
        ...state,
        meals: state.meals,
        createMealsLoading: false,
        createMealError: payload
      };
    case UPDATE_MEAL_SUCCESS:
      return {
        ...state,
        meals: state.meals.map(meal => (meal._id === payload._id) ? payload : meal),
        mealsLoading: false
      };
    case UPDATE_MEAL_ERROR:
      return {
        ...state,
        meals: null,
        mealsLoading: false,
        mealsError: payload
      };
    case HANDLE_MEAL_QTY:
      return {
        ...state,
        meals: action.orderedMeals,
        orderTotal: action.orderTotal,
        orderQty: action.orderQty
      };
    default:
      return state;
  }
}

export default meals;