import React from 'react';
import { Button } from 'antd';
import { FaCheckCircle } from 'react-icons/fa';
import { starRating } from '../../helpers/utils';
import GoogleIcon from '../../assets/images/GoogleIcon.png';
import AlvaroFitness from '../../assets/images/af-logo-white.png';
import { useSelector } from 'react-redux';
import { IoMdSpeedometer } from 'react-icons/io';

export const LandingHeader = (props) => {
  const {
    color,
    title,
    subtitle,
    btnAction,
    video,
    image,
    btnText,
    bulletPoints,
    textOverTitle,
  } = props;
  const isMobileView = useSelector((state) => state.global.isMobileView);

  const headerLogic = () => {
    if (video) {
      return { height: '100vh' };
    } else if (!isMobileView) {
      return { height: 'calc(100vh - 80px)' };
    } else {
      return { height: 'auto' };
    }
  };

  return (
    <div className='header' style={headerLogic()}>
      {video && <img className='no-nav-logo' src={AlvaroFitness} alt='logo' />}
      {video && (
        <>
          <video
            className='header-video'
            autoPlay
            muted
            loop
            playsInline
            preload='metadata'
            poster='your-placeholder-image.jpg'
          >
            <source src={video} type='video/mp4' />
            Your browser does not support the video tag.
          </video>

          <div className='overlay'></div>
        </>
      )}
      <div
        className='header-content'
        style={{ alignContent: image ? 'flex-start' : '' }}
      >
        <div
          className={`header-container ${
            !image ? 'align-center' : 'width-for-img'
          }`}
        >
          <div className='header-container-title'>
            {textOverTitle && (
              <p className='text-gradient eyebrow-text bold mb-10'>
                {textOverTitle}
              </p>
            )}
            <p
              className={`title program-title ${!image ? 'text-center' : ''}`}
              style={{ color: video && 'white' }}
            >
              {title}
            </p>
          </div>

          {subtitle && (
            <div style={{ marginTop: '-10px' }}>
              <p
                className='big-subtitle'
                style={{
                  maxWidth: '450px',
                  color: video && 'white',
                  textAlign: video && 'center',
                }}
              >
                {subtitle}
              </p>
            </div>
          )}

          <div className={`${!image ? 'grid justify-center' : ''}`}>
            <Button
              className='landing-button mb-30'
              onClick={btnAction}
              type='primary'
              style={{ background: video && 'blue' }}
            >
              {btnText}
            </Button>
            {bulletPoints && (
              <div
                style={{
                  background: '#f0f0f0',
                  padding: '20px',
                  borderRadius: '20px',
                }}
              >
                {bulletPoints.map((b) => (
                  <div className='grid-bullet-point'>
                    <FaCheckCircle
                      className='mr-10'
                      style={{ color: '#2761F1', marginTop: '5px' }}
                    />
                    <p className='subtitle' style={{ fontSize: '16px' }}>
                      {b}
                    </p>
                  </div>
                ))}
              </div>
            )}
            <div className='header-reviews'>
              <div className='flex center-aligned'>
                <img
                  className='mr-10'
                  style={{ height: '50px' }}
                  src={GoogleIcon}
                  alt='Google'
                  loading='lazy'
                />
                <div>
                  {starRating(5)}
                  <p
                    className='subtitle'
                    style={{ textAlign: 'center', color: video && 'white' }}
                  >
                    5.0 Reviews
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {image ? (
          <div className='img'>
            <img src={image} alt={'Alvaro'} loading='lazy' />
          </div>
        ) : null}
      </div>
    </div>
  );
};
