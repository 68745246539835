import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Popover, Select } from 'antd';
import MacronutrientIcon from '../assets/images/fruit.svg';
import OneRepMaxIcon from '../assets/images/weightlifting.svg';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { feetToCentimeters } from '../helpers/utils';
import { MacronutrientCalculatorComponent } from '../components/MacroNutrientCalculatorComponent';
import { setMacronutrientAnswers } from '../ducks/Global/actions';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../helpers/utils';
import {
  generateImperialHeightOptions,
  generateMetricHeightOptions,
} from '../helpers/utils';
import '../index.css';

const { Option } = Select;

export const MacronutrientCalculator = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobileView = useSelector((state) => state.global.isMobileView);
  const macronutrientAnswers = useSelector(
    (state) => state.global.macronutrientAnswers
  );
  const [isLoading, setIsLoading] = useState(false);

  const [results, setResults] = useState({
    bmr: 0,
    maintenanceCalories: 0,
    macrosForEachGoal: {},
  });
  const [activeTool, setActiveTool] = useState(null);

  const [shouldNavigate, setShouldNavigate] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname === '/tools/macronutrient-calculator') {
      setActiveTool({
        key: 1,
        name: 'Macronutrient Calculator',
        cardImg: MacronutrientIcon,
        cardSubtitle:
          'Get the perfect amount of calories, protein, carbs, and fat you should be eating every day.',
        active: true,
        about:
          'Discover your ideal macronutrient intake for building muscle, losing fat, and increasing strength with the Alvaro Fitness Calculator. Determine how much protein, carbs, and fats you should eat every day to achieve your fitness goals.',
      });
    } else if (location.pathname === '/tools/one-rep-max-calculator') {
      setActiveTool({
        key: 2,
        name: 'One Rep Max Calculator',
        cardImg: OneRepMaxIcon,
        cardSubtitle:
          'Calculate your one-repetition maximum (1RM) to optimize your strength training.',
        active: false,
        about:
          "Enhance your strength training regimen with the One Rep Max Calculator. Estimate your one-repetition maximum for various exercises, allowing you to customize your workout intensity and track your progress over time. Whether you're a beginner or an experienced lifter, this tool can help you reach your strength goals.",
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (shouldNavigate && results.bmr !== 0) {
      setIsLoading(true);
      setTimeout(() => {
        navigate('/tools/macronutrient-calculator/results', {
          state: {
            macronutrientAnswers,
            results,
            isMobileView,
          },
        });
        setShouldNavigate(false);
        setIsLoading(false);
      }, 2000);
    }
  }, [shouldNavigate, results, navigate]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (
      macronutrientAnswers.unit === 'imperial' &&
      !macronutrientAnswers.height.includes("'")
    ) {
      // Only update if the unit is imperial and the height is not already in feet/inches
      dispatch(
        setMacronutrientAnswers({
          ...macronutrientAnswers,
          height: "5'10",
        })
      );
    } else if (
      macronutrientAnswers.unit === 'metric' &&
      macronutrientAnswers.height.includes("'")
    ) {
      // Only update if the unit is metric and the height is still in feet/inches
      dispatch(
        setMacronutrientAnswers({
          ...macronutrientAnswers,
          height: '170',
        })
      );
    }
  }, [macronutrientAnswers.unit]);

  const heightOptions =
    macronutrientAnswers.unit === 'imperial'
      ? generateImperialHeightOptions(t)
      : generateMetricHeightOptions(t);

  const questionOptions = {
    gender: {
      name: t('questions.gender.name'),
      options: [
        { name: t('questions.gender.options.male'), value: 'male' },
        { name: t('questions.gender.options.female'), value: 'female' },
      ],
    },
    age: {
      name: t('questions.age.name'),
      label: t('questions.age.label'),
    },
    weight: {
      name: t('questions.weight.name'),
      label: t('questions.weight.label'),
    },
    height: {
      name: t('questions.height.name'),
      options: heightOptions,
    },
    activityFactor: {
      name: t('questions.activityFactor.name'),
      options: [
        { name: t('questions.activityFactor.options.1.2'), value: 1.2 },
        { name: t('questions.activityFactor.options.1.375'), value: 1.375 },
        { name: t('questions.activityFactor.options.1.55'), value: 1.55 },
        { name: t('questions.activityFactor.options.1.725'), value: 1.725 },
        { name: t('questions.activityFactor.options.1.9'), value: 1.9 },
      ],
    },
    bmrFormula: {
      name: t('questions.bmrFormula.name'),
      options: [
        {
          name: t('questions.bmrFormula.options.mifflin-st-jeor.name'),
          value: 'mifflin-st-jeor',
          explanation: t(
            'questions.bmrFormula.options.mifflin-st-jeor.explanation'
          ),
        },
        {
          name: t('questions.bmrFormula.options.katch-mcardle.name'),
          value: 'katch-mcardle',
          explanation: t(
            'questions.bmrFormula.options.katch-mcardle.explanation'
          ),
        },
        {
          name: t('questions.bmrFormula.options.harris-benedict.name'),
          value: 'harris-benedict',
          explanation: t(
            'questions.bmrFormula.options.harris-benedict.explanation'
          ),
        },
      ],
    },
    goal: {
      name: t('questions.goal.name'),
      options: [
        {
          name: t('questions.goal.options.rapid-weight-loss.name'),
          value: 'rapid-weight-loss',
          explanation: t(
            'questions.goal.options.rapid-weight-loss.explanation'
          ),
        },
        {
          name: t('questions.goal.options.moderate-weight-loss.name'),
          value: 'moderate-weight-loss',
          explanation: t(
            'questions.goal.options.moderate-weight-loss.explanation'
          ),
        },
        {
          name: t('questions.goal.options.slow-weight-loss.name'),
          value: 'slow-weight-loss',
          explanation: t('questions.goal.options.slow-weight-loss.explanation'),
        },
        {
          name: t('questions.goal.options.maintain-weight.name'),
          value: 'maintain-weight',
          explanation: t('questions.goal.options.maintain-weight.explanation'),
        },
        {
          name: t('questions.goal.options.slow-weight-gain.name'),
          value: 'slow-weight-gain',
          explanation: t('questions.goal.options.slow-weight-gain.explanation'),
        },
        {
          name: t('questions.goal.options.moderate-weight-gain.name'),
          value: 'moderate-weight-gain',
          explanation: t(
            'questions.goal.options.moderate-weight-gain.explanation'
          ),
        },
        {
          name: t('questions.goal.options.rapid-weight-gain.name'),
          value: 'rapid-weight-gain',
          explanation: t(
            'questions.goal.options.rapid-weight-gain.explanation'
          ),
        },
      ],
    },
  };

  const handleDropdownValue = (field, value) => {
    if (field === 'unit') {
      dispatch(
        setMacronutrientAnswers({
          ...macronutrientAnswers,
          unit: value,
        })
      );
    } else {
      dispatch(
        setMacronutrientAnswers({
          ...macronutrientAnswers,
          [field]: value,
        })
      );
    }
  };

  const renderDropdown = (options, label, name) => {
    let selectedOption = options.filter(
      (option) => option.value === macronutrientAnswers[name]
    )[0];

    return (
      <div className='mb-20'>
        {label === 'Formula' ? (
          <div className='flex align-center'>
            <p className='label'>{label}</p>
            <Popover
              trigger='click'
              content={
                <p
                  style={{ fontSize: '14px', width: '100%', maxWidth: '100px' }}
                >
                  {selectedOption.explanation}
                </p>
              }
            >
              <QuestionCircleOutlined
                style={{ fontSize: '10px', margin: '0 0 5px 3px' }}
              />
            </Popover>
          </div>
        ) : (
          <p className='label white'>{label}</p>
        )}
        <Select
          onSelect={(value) => handleDropdownValue(name, value)}
          value={macronutrientAnswers[name]}
          style={{ width: '100%' }}
          dropdownStyle={{
            borderRadius: '10px',
          }}
        >
          {options.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.name}
            </Option>
          ))}
        </Select>
        {name === 'goal' &&
          options.map(
            (option) =>
              option.value === macronutrientAnswers[name] && (
                <p
                  className='subtitle white'
                  style={{ margin: '10px 0 0 10px' }}
                >
                  {option.explanation}
                </p>
              )
          )}
      </div>
    );
  };

  const getLabelWithUnit = (label, name) => {
    console.log(`name: ${name}, unit: ${macronutrientAnswers.unit}`);
    if (name === 'weight') {
      return macronutrientAnswers.unit === 'imperial'
        ? `${label} (${t('lbs')})`
        : `${label} (${t('kg')})`;
    }
    if (name === 'height') {
      return macronutrientAnswers.unit === 'imperial'
        ? `${label} (${t('feet/inches')})`
        : `${label} (${t('cm')})`;
    }
    if (name === 'bodyFatPercentage') {
      return t(label);
    }
    return t(label);
  };

  const renderInputField = (label, name) => {
    return (
      <div className='input-container mb-20'>
        <p className='label white'>{getLabelWithUnit(label, name)}</p>
        <Input
          onChange={(e) => {
            handleDropdownValue(name, e.target.value);
          }}
          type='number'
          value={macronutrientAnswers[name] || ''}
        />
      </div>
    );
  };

  const calculateBMR = (macroAnswers) => {
    let feet, inches;
    let heightInCm;

    if (macroAnswers.unit === 'imperial') {
      [feet, inches] = macroAnswers.height.split("'");
      heightInCm = feetToCentimeters(Number(feet), Number(inches));
    } else if (macroAnswers.unit === 'metric') {
      heightInCm = Number(macroAnswers.height);
    }

    let bmrFormula = macroAnswers.bmrFormula;
    let weight =
      macroAnswers.unit === 'imperial'
        ? Number(macroAnswers.weight) * 0.453592
        : macroAnswers.weight;
    let age = Number(macroAnswers.age);
    let lbm = weight * (1 - macroAnswers.bodyFatPercentage / 100);
    let bmr = 0;

    if (macroAnswers.gender === 'male') {
      if (bmrFormula === 'mifflin-st-jeor') {
        // Mifflin-St Jeor (Recommended):
        bmr = 10 * weight + 6.25 * heightInCm - 5 * age + 5;
      } else if (bmrFormula === 'harris-benedict') {
        // Harris-Benedict:
        bmr = 88.362 + 13.397 * weight + 4.799 * heightInCm - 5.677 * age;
      } else {
        // Katch-McArdle:
        bmr = 370 + 21.6 * lbm;
      }
    } else if (macroAnswers.gender === 'female') {
      if (bmrFormula === 'mifflin-st-jeor') {
        // Mifflin-St Jeor (Recommended):
        bmr = 10 * weight + 6.25 * heightInCm - 5 * age - 161;
      } else if (bmrFormula === 'harris-benedict') {
        // Harris-Benedict:
        bmr = 447.593 + 9.247 * weight + 3.098 * heightInCm - 4.33 * age;
      } else {
        // Katch-McArdle:
        bmr = 370 + 21.6 * lbm;
      }
    }
    return bmr;
  };

  const calculateCaloriesAndMacros = (bmr, callback) => {
    let { activityFactor, macroPreference, goal, customMacros } =
      macronutrientAnswers;
    const maintenanceCalories = Math.round(bmr * activityFactor);
    const goals = [
      { key: 'rapid-weight-loss', adjustment: -1000 },
      { key: 'moderate-weight-loss', adjustment: -500 },
      { key: 'slow-weight-loss', adjustment: -250 },
      { key: 'maintain-weight', adjustment: 0 },
      { key: 'slow-weight-gain', adjustment: 250 },
      { key: 'moderate-weight-gain', adjustment: 500 },
      { key: 'rapid-weight-gain', adjustment: 1000 },
    ];

    // Function to calculate macros based on calorie percentages
    const calculateMacros = (
      calories,
      proteinPercent,
      carbPercent,
      fatPercent
    ) => ({
      protein: Math.round((proteinPercent * calories) / 4),
      carbs: Math.round((carbPercent * calories) / 4),
      fat: Math.round((fatPercent * calories) / 9),
    });

    const macroRatios = {
      balanced: [0.35, 0.35, 0.3],
      'low-carb': [0.4, 0.2, 0.4],
      'high-carb': [0.3, 0.5, 0.2],
      'high-protein': [0.4, 0.3, 0.3],
      ketogenic: [0.4, 0.1, 0.5],
      custom: [
        customMacros.protein / 100,
        customMacros.carbs / 100,
        customMacros.fat / 100,
      ],
    };

    let macrosForEachGoal = {};

    // Loop through goals and assign macros for each one based on the preference
    goals.forEach(({ key, adjustment }) => {
      const calories = maintenanceCalories + adjustment;
      const [proteinPercent, carbPercent, fatPercent] =
        macroRatios[macroPreference] || macroRatios['balanced'];

      macrosForEachGoal[key] = {
        calories,
        description:
          questionOptions.goal.options
            .find((option) => option.value === key)
            ?.name.split('~')[1] || '',
        ...calculateMacros(calories, proteinPercent, carbPercent, fatPercent),
        isSelected: key === goal,
      };
    });

    setResults((prevResults) => ({
      ...prevResults,
      maintenanceCalories,
      macrosForEachGoal,
    }));

    // Optional callback function execution after state update
    if (typeof callback === 'function') {
      callback();
    }
  };

  const handleCalculateAndShowResults = () => {
    const bmr = calculateBMR(macronutrientAnswers);

    setResults((prevResults) => ({
      ...prevResults,
      bmr: Math.round(bmr),
    }));

    calculateCaloriesAndMacros(bmr);

    setShouldNavigate(true);
  };

  return (
    <>
      <div className='tool-heading-container landing-page black-background'>
        <div className='tool-heading-content'>
          <div className='title-container'>
            <h1 className='title white'>{t('landingPage.title')}</h1>
            <p className='subtitle white'>{t('landingPage.description')}</p>
          </div>

          <div className={activeTool ? 'tools-page' : ''}>
            <div id='widgetContainer' className='tool'>
              <MacronutrientCalculatorComponent
                {...props}
                questionOptions={questionOptions}
                renderDropdown={renderDropdown}
                renderInputField={renderInputField}
                macronutrientAnswers={macronutrientAnswers}
                isLoading={isLoading}
                handleCalculateAndShowResults={handleCalculateAndShowResults}
                handleDropdownValue={handleDropdownValue}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
