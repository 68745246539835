import React from 'react';
import { connect } from 'react-redux';
import ManageMealsPage from '../presentational/ManageMeals';
import { getIngredients } from '../ducks/Ingredients/actions';
import { createMeal, getMeals } from '../ducks/Meals/actions';
import { Spin } from 'antd';

class ManageMeals extends React.Component {

  componentDidMount(){
    this.props.getIngredients();
    this.props.getMeals();
  }

  render(){
    return (
      <div className='manage-meals-page'>
       {this.props.ingredientsList && this.props.meals ? <ManageMealsPage {...this.props}/> : <Spin />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    ingredientsList: state.ingredients.ingredients,
    createMealsLoading: state.meals.createMealsLoading,
    meals: state.meals.meals,
    createMealError: state.meals.createMealError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getIngredients: () => dispatch(getIngredients()),
    createMeal: (meal) => dispatch(createMeal(meal)),
    getMeals: () => dispatch(getMeals())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageMeals);