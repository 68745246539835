import React, { Component } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './index.css';

class ConfirmationPage extends Component {
  render() {
    return (
      <div className='confirmation-page'>
        <div className='title'>
          <CheckCircleOutlined
            style={{ fontSize: '70px', color: '#4EF704', marginBottom: '30px' }}
          />
          <p className='confirmation-title'>{`THANK YOU, ${this.props.createdMeal.customer.name.toUpperCase()}!`}</p>
          <p className='confirmation-subtitle'>Your order has been placed.</p>
        </div>
        <Button
          onClick={() => this.props.navigation('/meals/menu')}
          type='primary'
          style={{ width: '50%', margin: '0 auto' }}
        >
          Back to Menu
        </Button>
      </div>
    );
  }
}

export default ConfirmationPage;
