import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS
  } from './constants';
  
  const initialState = {
    orders: [],
    ordersLoading: false,
    ordersError: '',
    createOrderLoading: false,
    createOrderErr: null,
    createdOrder: null
  };
  
  function orders(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ORDER:
        return {
          ...state,
          orders: state.orders,
          createOrderLoading: true,
          createdOrder: null
        };
      case CREATE_ORDER_SUCCESS:
        return {
          ...state,
          orders: [...state.orders, payload],
          createOrderLoading: false,
          createdOrder: payload
        };
      case CREATE_ORDER_ERROR:
        return {
          ...state,
          orders: null,
          createOrderLoading: false,
          createOrderErr: payload,
          createdOrder: null
        };
      case GET_ORDERS:
        return {
          ...state,
          orders: null,
          ordersLoading: true,
        };
      case GET_ORDERS_SUCCESS:
        return {
          ...state,
          orders: payload,
          ordersLoading: false
        };
      case GET_ORDERS_ERROR:
        return {
          ...state,
          orders: null,
          ordersLoading: false,
          ordersError: payload
        };
      case DELETE_ORDER:
        return {
          ...state,
          deleteOrderLoading: true
        };
      case DELETE_ORDER_SUCCESS:
        return {
          ...state,
          orders: state.orders.filter(order => order._id !==  action.id),
          deleteOrderLoading: false
        };
      default:
        return state;
    }
  }
  
  export default orders;