import React, { Component } from 'react';
import Meal from './meal';
import './index.css';

export default class Meals extends Component {
  state={orderedMeals: []}

  componentDidMount(){
    this.setState({orderedMeals: this.props.meals})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.meals !== this.props.meals){
      this.setState({orderedMeals: this.props.meals});
    }
  }

  render() {
    return (
      <div className='meals'>
        {this.state.orderedMeals && this.state.orderedMeals.map(meal => <Meal {...this.props} key={meal._id} meal={meal} />)}
      </div> 
    )
  }
}